import React, { useState, useEffect } from 'react'

import {
  useLocation,
  Redirect,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import keyBy from 'lodash/keyBy'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  Slide,
  Button,
} from '@material-ui/core'


import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'
import SurveyStep from 'ui/components/molecules/SurveyStep'

const { tenantConfig } = useConfig()
// const variant = 'self'
// const steps = tenantConfig.survey.pathways[variant].steps
const questions = keyBy(tenantConfig.survey.questions, 'id')

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  slide: {
    minHeight: 400,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
}))

const useQueryParams = location => {
  try {
    const search = location.search.substring(1)
    if (!search) return {}

    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}',
      (key, value) => { return key === '' ? value: decodeURIComponent(value) }
    )
  } catch {
    return {}
  }
}

const Survey = observer(({ steps }) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const store = useStore()

  const [nav, setNav] = useState({ prev: null, next: null })
  const [slideIn, setSlideIn] = useState(true)
  const [slideDirection, setSlideDirection] = useState('left')

  const slideToStep = step => {

    const directions = step < store.survey.step
      ? ['left', 'right'] : ['right', 'left']

    setSlideDirection(directions[0])
    setSlideIn(false)

    document.getElementById('scroll-content').scrollTop = 0

    setTimeout(() => {
      store.survey.set('step', step)
      setSlideDirection(directions[1])
      setSlideIn(true)
    }, 500)
  }

  const handleNavigateStep = (step, label) => () => {
    track(`surveyStepClick`, {
      category: 'Survey',
      label: label,
    })

    if (typeof step === 'number') {
      slideToStep(step)
      return
    }

    if (typeof step === 'string' && step === 'finish') {
      store.survey.finish()
      return
    }
  }

  const onEval = () => {
    store.survey.dump()
  }

  const onRestart = () => {
    track('surveyRestart', {
      category: 'Survey',
      label: 'Restart',
    })

    store.survey.init(store.survey.pathway)
  }

  // update nav buttons

  useEffect(() => {
    const prev = store.survey.prevStep !== null
      ? {
        label: 'Go Back',
        step: store.survey.prevStep,
      } : null

    const next = store.survey.nextStep !== null
      ? {
        label: 'Next',
        step: store.survey.nextStep,
        disabled: !store.survey.currentStepValid,
      } : {
        label: 'Finish',
        step: 'finish',
        disabled: false,
      }

    setNav({ prev, next })
  }, [
    store.survey.step,
    store.survey.prevStep,
    store.survey.nextStep,
    store.survey.currentStepValid,
  ])

  return (
    <Card className={classes.root}>

      <Slide
        in={slideIn}
        direction={slideDirection}
        // timeout={500}
      >
        <div>
          <SurveyStep
            pathway={store.survey.pathway}
            questions={questions}
            stepConfig={store.survey.currentStep}
          />
        </div>

      </Slide>

      <div className={classes.buttons}>
        <Button
          // color="secondary"
          onClick={() => onRestart()}
        >
          Restart
        </Button>

        {false && (
          <Button
            // color="secondary"
            onClick={() => onEval()}
          >
            Eval
          </Button>
        )}

        {nav.prev && (
          <Button
            // color="secondary"
            onClick={handleNavigateStep(nav.prev.step, nav.prev.label)}
          >
            {nav.prev.label}
          </Button>
        )}

        {nav.next && (
          <Button
            disabled={nav.next.disabled}
            color="primary"
            onClick={handleNavigateStep(nav.next.step, nav.next.label)}
          >
            {nav.next.label}
          </Button>
        )}
      </div>
    </Card>
  )
})

const SurveyEntry = observer(() => {
  const { track } = useAnalytics()
  const store = useStore()
  const location = useLocation()
  const { pathway } = useQueryParams(location)

  const pathwayConfig = tenantConfig.survey.pathways
    .find(p => p.pathways.includes(store.survey.pathway))

  // TODO: error handling
  const steps = pathwayConfig.steps

  // start

  useEffect(() => {
    track('surveyStart', {
      category: 'Survey',
      label: store.survey.pathway,
    })
  }, [store.survey.pathway, track])

  if (pathway) {
    store.survey.init(pathway)
    return <Redirect to={location.pathname} />
  }

  // TODO: config
  if (store.survey.finished) {
    return <Redirect to="/search" />
  }

  return <Survey steps={steps}/>
})

export default SurveyEntry

/* eslint-disable no-undef */

const appConfig = {
  version: 2,
  apiKey: '85799706-5B59-49F5-87C0-F6D5DF6B1E6E',
  // apiURL: 'https://www.mcdapi.com/api/v1',
  // apiURL: 'https://qa-api-cie.azurewebsites.net/api/v1',
  apiURL: 'https://api-cie.azurewebsites.net/api/v1',
  //apiURL: 'https://www.mcdapi.com/api/v1/',
  // apiURL: 'https://uat-api-cie.azurewebsites.net/api/v1',
  directoryURL: 'https://www.mycommunitydirectory.com.au',
  diaryURL: 'https://www.mycommunitydiary.com.au',
  cdnURL: 'https://mcdcdn.blob.core.windows.net',
  tenant: process.env.TENANT,
}

const tenantConfig = require(`tenants/${process.env.TENANT}/config.js`).default

export const useConfig = () => {
  return {
    appConfig,
    tenantConfig,
  }
}

// import { useConfig } from 'config/config'
// const { appConfig, tenantConfig } = useConfig()
import { addDays } from 'date-fns'

// eslint-disable-next-line no-undef
const tenant = process.env.TENANT

export const migrations = [
  {
    version: 1,
    info: 'Mindcare Tab',
    migrate: ({ userData }) => {
      if (tenant === 'mindcare') {
        userData.ui.selectedTab = 0
        userData.params.categories = []
        userData.params.selectedCategories = null
      }
      return userData
    },
  },
  {
    version: -1,
    info: 'Calendar Reset',
    migrate: ({ userData }) => {
      if (userData?.params?.dateRange) {
        if (addDays(new Date(), -1) >= userData.params.dateRange.start) {
          userData.params.dateRange = {}
        }
      }
      return userData
    },
  },
]

import React from 'react'
import { observer } from 'mobx-react-lite'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

import { RouterLink, CieLink } from 'ui/components/atoms/Link'

// import { useStore } from '../../../data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  testLink: {
    fontSize: 24,
    fontWeight: 600,
  },
}))

const Scratch = observer(() => {
  // const store = useStore()
  const classes = useStyles()

  return (
    <Container className={classes.root}>

      <Grid spacing={3} container>

        <Grid xs={12} item>

          <RouterLink
            to="/"
          >
            Some Service Title
          </RouterLink>

          <br />

          <CieLink
            type="service"
            path="/Queensland/Brisbane/Communication___Information/Community_Information___Referral_Services/11373/111626/Community_Information_Support_Services_-_Brisbane"
            className={classes.testLink}
          >
            CISS
          </CieLink>

        </Grid>

      </Grid>

    </Container>
  )
})

export default Scratch

import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  // Typography,
 } from '@material-ui/core'

import BackButton from 'ui/components/atoms/BackButton'
import Footer from 'ui/components/molecules/Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100%',
    // maxHeight: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  main: {
    flex: '1 0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(12),
  },
}))

const ContentPage = ({ children, backButton = true }) => {
  const classes = useStyles()

  return (
    <PerfectScrollbar className={classes.root} id="scroll-content">
      <Container
        maxWidth="md"
        component="main"
        className={classes.main}
      >
        <div id="content-page-top" />
        {backButton && <BackButton />}
        {children}
      </Container>
      <Footer />
    </PerfectScrollbar>
  )
}

export default ContentPage

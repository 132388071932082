import React from 'react'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'

import SimpleMarkdown from 'ui/components/atoms/SimpleMarkdown'

import {
  Card,
  CardContent,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    // padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    // border: '1px solid black',
    borderRadius: 0,
    boxShadow: 'none',
    transform: 'none',
  },
}))

const MarkdownResultsHeader = observer(({
  // config,
  content,
}) => {
  const classes = useStyles()

  if (!content.id) return null

  return (
    <Card
      variant="outlined"
      className={classes.root}
    >
      <CardContent>
        <SimpleMarkdown>{content.body}</SimpleMarkdown>
      </CardContent>
    </Card>
  )
})

export default MarkdownResultsHeader

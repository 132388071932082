import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { makeStyles } from '@material-ui/core/styles'
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Drawer,
  Box,
  // Icon,
  IconButton,
  Button,
  Fab,
} from '@material-ui/core'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
// import { useResizeDetect } from 'ui/hooks/useResizeDetect'
import { usePlatform } from 'ui/hooks/usePlatform'

import LocationDialog from 'ui/components/molecules/LocationDialog'
import SearchParameters from 'ui/components/organisms/SearchParameters'
import SearchResults from 'ui/components/organisms/SearchResults'
import SearchResultsMap from 'ui/components/molecules/SearchResultsMap'
import Information from 'ui/components/molecules/Information'
import InfoOnly from 'ui/components/molecules/InfoOnly'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  hidden: {
    display: 'none !important',
  },
  tabPanel: {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  information: {
    flex: '1 1 0',
    overflow: 'hidden',
  },
  results: {
    flex: '1 1 0',
    overflow: 'hidden',
  },
  bottomToolbar: {
    padding: theme.spacing(1, 1),
    flex: '0 1 auto',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottomNavigation: {
    flex: '0 1 auto',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  paramaterDrawer: {
    maxHeight: 'calc(100vh - 70px)',
    // display: 'flex',
    // flexDirection: 'column',
  },
  parameters: {
  },
  searchFab: {
    position: 'absolute',
    bottom: 148,
    right: theme.spacing(2),
    fontSize: 16,
  },
  modeFab: {
    position: 'absolute',
    bottom: 128,
    right: theme.spacing(1),
    fontSize: 20,
  },
  modeIcon: {
    marginTop: 3,
  },
  bottomIcon: {
    fontSize: 20,
    padding: 1,
  },
  toolbarButton: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  buttonIcon: {
    fontSize: 14,
    padding: 1,
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}))

const TabPanel = ({ index, value, children, ...otherProps }) => {
  const classes = useStyles()
  const cn = clsx(classes.tabPanel, value !== index && classes.hidden)

  return (
    <div
      className={cn}
      role="tabpanel"
      id={`desktop-search-tabpanel-${index}`}
      aria-labelledby={`desktop-search-tab-${index}`}
      {...otherProps}
    >
      {children}
    </div>
  )
}

const SearchResultsPanel = observer(({ viewMode = 0, config }) => {
  const classes = useStyles()
  const store = useStore()

  if (!store.search.params.isSearchCategorySelected &&
    config.id !== 'favourites') return (
    <InfoOnly />
  )

  return (
    <>
      <div className={clsx(classes.results, viewMode !== 0 && classes.hidden)}>
        <PerfectScrollbar>
          <SearchResults searchId={config.options.search} />
        </PerfectScrollbar>
      </div>

      <div className={clsx(classes.results, viewMode !== 1 && classes.hidden)}>
        <SearchResultsMap searchId={config.options.search} />
      </div>
    </>
  )
})

const InformationPanel = () => {
  const classes = useStyles()

  return (
    <div className={classes.tabPanel}>
      <PerfectScrollbar>
        <Container maxWidth="md">
          <Box py={4}>
            <Information />
          </Box>
        </Container>
      </PerfectScrollbar>
    </div>
  )
}

const tabComponentsMap = {
  Information: InformationPanel,
  SearchResults: SearchResultsPanel,
}

const TabComponent = ({ value, index, viewMode, config }) => {
  const Component = tabComponentsMap[config.component]

  return (
    <TabPanel value={value} index={index}>
      {/* <div>Panel: {config.title}</div> */}
      <Component viewMode={viewMode} config={config} />
    </TabPanel>
  )
}

const viewModes = [
  {
    label: 'Map',
    icon: 'fas fa-map-marker-alt',
  },
  {
    label: 'Results',
    icon: 'far fa-list',
  },
]

const useDrawerStyles = makeStyles(theme => ({
  drawer: {
    maxHeight: window.innerHeight - 50,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contents: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 1210,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    width: 30,
    height: 30,
  },
}))

const OptionDrawer = ({
  id = 'none',
  anchor = 'bottom',
  open = false,
  onClose = () => undefined,
  // onCloseButtonClick = () => undefined,
  children,
}) => {
  // const classes = useStyles()
  const classes = useDrawerStyles()

  const handleCloseButtonClick = () => {
    onClose(id)
  }

  return (
    <Drawer
      {...{
        anchor,
        open,
        onClose,
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={handleCloseButtonClick}
      >
        <i className="fal fa-times fa-fw" />
      </IconButton>



      <div className={classes.drawer}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
        >
          <div className={classes.contents}>
            {children}
          </div>
        </PerfectScrollbar>
      </div>
      {/* <PerfectScrollbar>
        {children}
      </PerfectScrollbar> */}
    </Drawer>
  )
}

const MobileSearch = observer(() => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const store = useStore()
  const ui = store.search.ui
  const { tenantConfig } = useConfig()
  const tabs = tenantConfig.search.tabs

  const tab = ui.selectedTab
  const setTab = value => ui.setSelectedTab(value)

  const [viewMode, setViewMode] = React.useState(0)
  const [showLocation, setShowLocation] = useState(false)

  const [viewOptionsDrawer, setViewOptionsDrawer] = useState(null)

  const enableMap = store.search.params.isSearchCategorySelected && tabs[tab].map

  const parameterGroups = {
    search: tenantConfig.search?.parameterGroups?.mobileSearch,
    categories: tenantConfig.search?.parameterGroups?.mobileCategories,
  }

  const initLocation = tenantConfig.search?.location?.initLocation

  const { isBot } = usePlatform()

  useEffect(() => {
    const show = !isBot &&
      initLocation === 'dialog' &&
      !store.search.ui.locationDialogShown &&
      store.search.params.location === null

    setTimeout(() => {
      if (show) {
        track('searchLocationDialogOpen', {
          category: 'Search',
          label: 'Show',
        })
      }
      setShowLocation(show)
    }, 1000)
  }, [
    initLocation,
    store.search.params.location,
    store.search.ui.locationDialogShown,
    track,
  ])

  useEffect(() => {
    if (!viewOptionsDrawer) return

    track('searchOptionsDrawer', {
      category: 'Search',
      label: `Show ${viewOptionsDrawer}`,
    })
  }, [viewOptionsDrawer, track])

  const handleClickToggleSearch = (id, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
      return

    track('searchToolbarButton', {
      category: 'Search',
      label: `${id}`,
    })

    setViewOptionsDrawer(open ? id : null)
  }

  const handleOptionsDrawerClose = (id) => () => {
    track('searchOptionsDrawer', {
      category: 'Search',
      label: `Show ${id}`,
    })

    setViewOptionsDrawer(null)
  }

  const handleCloseLocationDialog = () => {
    track('searchLocationDialogClose', {
      category: 'Search',
      label: 'Close',
    })

    setShowLocation(false)
    store.search.ui.setLocationDialogShown(true)
  }

  const handleTabChange = (event, value) => {
    const tabc = tabs[value]

    track('searchToolbarSelectTab', {
      category: 'Search',
      label: tabc.title,
    })

    setTab(value)
  }

  const toggleMapView = () => {
    track('searchToggleMapView', {
      category: 'Search',
      label: viewModes[viewMode].label,
    })

    setViewMode(viewMode ^ 1)
  }

  const pg = parameterGroups[viewOptionsDrawer]

  return (
    <div className={classes.root}>
      <LocationDialog
        open={showLocation}
        onClose={handleCloseLocationDialog}
      />

      {tabs.map((t, i) => (
        <TabComponent key={i} value={tab} index={i} config={t} viewMode={viewMode} />
      ))}

      <OptionDrawer
        anchor="bottom"
        open={!!viewOptionsDrawer}
        onClose={handleOptionsDrawerClose(viewOptionsDrawer)}
      >
        <SearchParameters
          parameterGroup={pg}
        />
      </OptionDrawer>

      {true && <div className={classes.bottomToolbar}>
        {parameterGroups.search &&
          <Button
            color="primary"
            className={classes.toolbarButton}
            onClick={handleClickToggleSearch('search', true)}
          >
            <i className={clsx('fas fa-search', 'fa-fw', classes.buttonIcon)} />
            Search
          </Button>
        }

        {parameterGroups.categories &&
          <Button
            color="primary"
            className={classes.toolbarButton}
            onClick={handleClickToggleSearch('categories', true)}
          >
            <i className={clsx('far fa-stream', 'fa-fw', classes.buttonIcon)} />
            Categories
          </Button>
        }
      </div>}

      {enableMap && (
        <Fab
          size="medium"
          color="primary"
          className={classes.modeFab}
          onClick={toggleMapView}
        >
          <i className={clsx(viewModes[viewMode].icon, 'fa-fw', classes.modeIcon)} />
        </Fab>
      )}

      <div className={classes.bottomNavigation}>
        <BottomNavigation
          value={tab}
          showLabels
          onChange={handleTabChange}
        >
          {tabs.map((t, i) => (
            <BottomNavigationAction
              key={i}
              label={t.title}
              // icon={<i className={clsx(t.icon, classes.bottomIcon)} />}
              icon={<i className={clsx(t.icon, 'fa-fw', classes.bottomIcon)} />}
            />

          ))}
        </BottomNavigation>

      </div>

    </div>
  )
})

export default MobileSearch

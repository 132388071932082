import React from 'react'

import { observer } from 'mobx-react-lite'
import { addDays } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Input,
  useTheme,
} from '@material-ui/core'

import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider,
} from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import enLocale from 'date-fns/locale/en-AU'

const useStyles = makeStyles(() => ({
  fieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const DateRangeParam = observer(({
  value,
  onChange = () => undefined,
 }) => {
  const classes = useStyles()
  const theme = useTheme()

  const internalValue = value ? [value.start, value.end] : [null, null]

  const anytimeSelected = value?.delta === -1

  const minDate = new Date()
  const maxDate = addDays(new Date(), 90)

  const handleDateChange = newValue => {
    const dr = newValue?.some(x => x) ? {
      delta: -2,
      start: newValue[0],
      end: newValue[1],
    } : {
      delta: -1,
      start: null,
      end: null,
    }

    onChange(dr)
  }

  const handleAnytimeClick = () => {
    onChange({
      delta: -1,
      start: null,
      end: null,
    })
  }

  const RenderInput = (startProps, endProps) => {
    return (
      <div className={classes.fieldContainer}>
        <Input {...startProps.inputProps} placeholder="From" />
        <DateRangeDelimiter>→</DateRangeDelimiter>
        <Input {...endProps.inputProps} placeholder="To" />
      </div>
    )
  }

  return (
    <div>
      <Box pt={1} pb={2}>
        <Button
          variant="contained"
          size="small"
          color={anytimeSelected ? 'primary' : 'default' }
          onClick={handleAnytimeClick}
          disableElevation
        >
          Anytime
        </Button>
      </Box>

      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
        <DateRangePicker
          startText="From"
          endText="To"
          value={internalValue}
          onChange={handleDateChange}
          renderInput={RenderInput}
          desktopModeMediaQuery={theme.breakpoints.up("sm")}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>

    </div>
  )
})

export default DateRangeParam

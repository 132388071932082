import { types, getRoot } from 'mobx-state-tree'
import { values, toJS } from 'mobx'

import { Search } from './Search'
import { SearchParams } from './SearchParams'
import { SearchUI } from './SearchUI'
// import { Service } from './Service'
// import { Event } from './Event'

import { useConfig } from 'config/config'
import { asArray } from '../utils/misc-helpers'
import { toFavourite } from '../utils/search-utils'

const { tenantConfig } = useConfig()
// const { track } = useAnalytics()

export const SearchControl = types
.model({
  selectedResult: types.maybeNull(types.model({
      source: types.enumeration(['results', 'map']),
      // result: types.safeReference(types.union(Service, Event)),
      id: types.maybeNull(types.string, null),
    })
    .views(self => ({
      get control() {
        return getRoot(self).search
      },

      get result() {
        // console.log('get result', self.id)
        if (!self.id) return null

        try {
          const searchId = self.id.match(/^([^_])*/)[0]
          const search = self.control.searches.get(searchId)
          const result = self.id in search.resultsMap ? search.resultsMap[self.id] : null

          return result
        } catch {
          console.error('get selected result error')
          return null
        }
      },

    }))
  , null),
  searches: types.map(Search, {}),
  searchCount: 0,
  params: types.optional(SearchParams, {}),
  ui: types.optional(SearchUI, {}),
})

.volatile(() => ({
  contentRegions: {},
}))

.views(self => ({
  get tenant() {
    return getRoot(self).tenant
  },

  get selectedId() {
    return self.selectedResult ? self.selectedResult.result.id : null
  },

  get locationSummary() {
    if (self.params.location?.type === 'locality') return self.params.location?.summary
    return 'Australia'
  },

  get categoriesSummary() {
    // if (self.params.selectedCategories)
    const def = 'Healthcare Services'
    const sc = self.params.selectedCategoriesMeta

    if (sc.length === 1) {
      const sct = sc[0].title
      const scr = sct.endsWith('Services') ? sct : `${sct} Services`
      return scr
    }

    return def
  },

  get locationPath() {
    const lconfig = tenantConfig?.search?.router?.location
    if (!lconfig) return null

    const loc = self.params.location
    if (!loc) return null

    return `${lconfig.prefix}/${loc.stateUrl}/${loc.councilUrl}/${loc.url}`
  },

  get categoryPath() {
    const cconfig = tenantConfig?.search?.router?.category
    if (!cconfig) return null

    const sc = self.params.selectedCategoriesMeta
    if (sc.length === 0) return null

    const field = cconfig.field || 'title'
    const delimiter = cconfig.delimiter || '+'

    const cats = sc.map(c => c[field].replace(' ', '_')).join(delimiter)
    return `${cconfig.prefix}/${cats}`
  },

  get path() {
    const rconfig = tenantConfig?.search?.router
    if (!rconfig) return null

    const pc = [rconfig.root, self.locationPath, self.categoryPath]
      .filter(x => x)
      .join('/')
      .replace(/(\/)+/, '/')

    return pc
  },

  // TODO: fix up all the consumers of theses things so everything is a []

  get activeCategories() {
    // this is the categories applicable to any given search
    // i.e. selected || visible

    const p = self.params

    if (p.selectedCategories) return p.selectedCategories
    return self.params.visibleCategories.map(c => c.id)
  },

  get activeCategoriesArray() {
    return asArray(self.activeCategories)
  },

  get activeCategoriesSet() {
    return new Set(self.activeCategoriesArray)
  },

  get combinedCategories() {
    return self.tenant.combinedCategories(self.activeCategories)
  },

  get combinedTags() {
    return self.tenant.combinedTags(self.params.selectedCategories)
  },

  get combinedDiaryFormat() {
    return self.tenant.combinedDiaryFormat(self.params.selectedCategories)
  },

  get combinedCommissionedServices() {
    return self.tenant.combinedCommissionedServices(self.params.selectedCategories)
  },


  get contentRegion() {
    if (!self.params.location) return 'default'

    const rid = self.params.location.id

    return rid in self.contentRegions ? self.contentRegions[rid].id : 'default'
  },

  selectedInformation(type = 'information') {
    const tenant = getRoot(self).tenant
    const id = self.params.selectedCategories || 'default'
    const region = self.contentRegion

    const noContent = {
      id: null,
      region: 'default',
      title: 'Content Not Found',
      body: '',
    }

    if (typeof id !== 'string') return noContent

    const { title } = tenant.getCategory(id)

    const content = tenant.getContent({ type, region, id })
    const body = content && content.body

    if (!title || !body) return noContent

    return { id, region, title, body }
  },

  get selectedInformationCategory() {
    const tenant = getRoot(self).tenant
    const id = self.params.selectedCategories || 'default'

    if (typeof id !== 'string') return null

    const category = tenant.getCategory(id)
    return category
  },

  getStaticContent(id) {
    const tenant = getRoot(self).tenant
    const region = self.contentRegion

    const noContent = {
      id: null,
      region: 'default',
      title: 'Content Not Found',
      body: '',
    }

    const content = tenant.getContent({ type: 'static', region, id })

    return content || noContent
  },

  get favouritesSearch() {
    return values(self.searches).find(s => s.type === 'favourites')
  },

  isFavourite(id) {
    if (!self.favouritesSearch) return false

    const fid = id.match(/_fav$/) ? id : `${id}_fav`
    return self.favouritesSearch.hasResult(fid)
  },

}))

.actions(self => ({
  // default filters are volatile
  loadDefaultFilters() {
    Array.from(self.searches.values())
      .forEach(v => {
        const sc = tenantConfig.search?.searches[v.id]
        if (sc.defaultFilter) v.setDefaultFilter(sc.defaultFilter)
      })

    // (tenantConfig.search?.searches || {})
    //   .filter(s => s.id !== 'favourites')
    //   .forEach(s => {
    //     if (!s.defaultFilter) return
    //     self.searches[s.id].setDefaultFilter(s.defaultFilter)
    //   })
  },

  initLocation() {
    const lc = tenantConfig.search?.location

    if (lc && lc.initLocation === 'default' && lc.defaultLocation && !self.params.location) {
      self.params.setLocation(lc.defaultLocation)
      return
    }
  },

  setSelected(source, result) {
    try {
      self.selectedResult = result ? { source, id: result.id } : null
    } catch {
      console.error('select error')
      self.selectedResult = null
    }
  },

  loadContentRegions(contentRegions = []) {
    self.contentRegions = contentRegions.reduce((obj, val) => {
      // TODO: dedupe
      val.regions.forEach(r => obj[r] = ({
        id: val.id,
        title: val.title,
      }))
      return obj
    }, {})
  },

  clear() {
    self.selectedResult = null

    Array.from(self.searches.values())
    .forEach(v => v.clear())
  },

  initSearch() {
    // analytics.track('searchControlInit', {
    //   category: 'SearchControl',
    //   label: 'Init',
    // })

    self.selectedResult = undefined
    self.searchCount = self.searchCount + 1

    Array.from(self.searches.values())
      .forEach(v => v.initSearch())
  },

  loadMore({ search }) {
    // track('searchControlLoadMore', {
    //   category: 'SearchControl',
    //   label: 'Load More',
    // })

    self.selectedResult = undefined
    self.searches.get(search).loadMore()
  },

  setShowAllCategories(value) {
    self.ui.setShowAllCategories(value)

    const visible = self.params.visibleCategories.map(c => c.id)

    // single category mode deselect

    if (tenantConfig.options.categoryMode === 'single' &&
      !visible.includes(self.params.selectedCategories)) {
      self.params.setSelectedCategories(null)
    }
  },

  loadFavourites(favourites) {
    if (!self.favouritesSearch || !favourites) return
    self.favouritesSearch.setResults(favourites)
  },

  toggleFavourite(result) {
    const fs = self.favouritesSearch

    if (!fs) {
      console.error('Favourites search not found')
      return
    }

    const fav = toJS(result.id.match(/_fav$/)
      ? result : toFavourite(result, 'favourites')
    )

    if (!fs.hasResult(fav.id)) {
      fs.insert(fav)
    } else {
      fs.removeById(fav.id)
    }
  },


}))

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#f0f',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  },
}))

const Logo = ({ srcId, ...otherProps }) => {
  const classes = useStyles()
  const { tenantConfig } = useConfig()

  const src = srcId in tenantConfig.assets ? tenantConfig.assets[srcId] : null

  return src ? (
    <div className={classes.root}>
      {/* Logo {srcId} */}

      <img className={classes.image} src={src} alt={srcId} />
    </div>

  ) : null
}

export default Logo

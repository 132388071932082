import { types } from 'mobx-state-tree'

export const Location = types
  .model({
    type: types.literal('locality'),
    id: 0,
    default: false,
    label: '',
    councilId: 0,
    state: '',
    postcode: '',
    lat: 0.0,
    lng: 0.0,
    url: '',
    councilUrl: '',
    stateUrl: '',
  })

  .views(self => ({

    get name() {
      return self.url.replace('_', ' ')
    },

    get summary() {
      return `${self.name}, ${self.state}`
    },
  }))

import React from 'react'
import { observer } from 'mobx-react-lite'

import SimpleMarkdown from 'ui/components/atoms/SimpleMarkdown'
import SupportForm from 'ui/components/molecules/SupportForm'

import { useStore } from 'data/store/store'
// import StaticMarkdown from 'ui/components/molecules/StaticMarkdown'
import {
  Box,
} from '@material-ui/core'

const Help = observer((props) => {
  const store = useStore()
  const content = store.search.getStaticContent(props.contentId)

  return (
    <div>
      <Box mb={4}>
        <SimpleMarkdown>{content.body}</SimpleMarkdown>
      </Box>

      <SupportForm />

    </div>
  )
})

export default Help

import { types, getParent } from 'mobx-state-tree'
// import { useConfig } from 'config/config'

export const SearchUI = types
  .model({
    locationDialogShown: false,
    showAllCategories: false,
    selectedTab: 0,
  })

  .views(self => ({
    get params() {
      return getParent(self).params
    },
  }))

  .actions(self => ({
    setShowAllCategories(val) { self.showAllCategories = val },
    setSelectedTab(val) { self.selectedTab = val },
    setLocationDialogShown(val) { self.locationDialogShown = val },

    clear() {
      self.showAllCategories = false
      self.selectedTab = 0
    },

  }))

import { types, flow, getRoot } from 'mobx-state-tree'
import { toJS } from 'mobx'

import _ from 'lodash'
import { analytics } from 'data/analytics/analytics'

import { buildQuery } from '../utils/query-builder'
import { buildFilter } from '../utils/filter-builder'

import {
  searchDirectory,
  searchDirectoryHealth,
  searchDiary,
  searchDiaryHealth,
} from '../../api/cie-api'
import {
  buildDirectorySearch,
  buildDirectorySearchHealth,
  buildDirectorySearchWAPHA,
  buildDiarySearch,
 } from '../utils/search-utils'


import { Service } from './Service'
import { Event } from './Event'

import { useConfig } from 'config/config'

const { tenantConfig } = useConfig()

// map of parameter mappings for each search

const queries = _.keyBy(tenantConfig.search.queries, 'id')

// map of build/search functions to search definition

const searchInterfaces = {
  directory: {
    buildParams: buildQuery,
    search: searchDirectory,
  },
  // directoryHealth: {
  //   buildParams: buildDirectorySearchHealth,
  //   search: searchDirectoryHealth,
  // },
  // directoryWAPHA: {
  //   buildParams: buildDirectorySearchWAPHA,
  //   search: searchDirectory,
  // },
  diary: {
    buildParams: buildQuery,
    search: searchDiary,
  },
  diaryHealth: {
    buildParams: buildQuery,
    search: searchDiaryHealth,
  },
  favourites: {
    buildParams: null,
    search: null,
  },
}

// default location for map

const australia = {
  center: { lat: -28.6, lng: 134.0 },
  zoom: 5,
  selected: null,
}

const Result = types.union(Service, Event)

export const Search = types
  .model({
    id: '',
    type: types.enumeration(Object.keys(searchInterfaces)),
    filterMode: types.optional(
      types.enumeration('FilterMode', ['filters', 'categories', 'tags', 'commissioned-services']),
      'filters',
    ),
    loading: false,
    error: false,
    total: 0,
    pageSize: 30,
    results: types.array(Result),
    showSiteSupport: false,
    resultsHeader: types.maybeNull(types.model({
      type: '',
      prefix: '',
    }), null),
    // resultsMap: types.map(types.safeReference(Result))
  })

  .volatile(() => ({
    defaultFilter: {},
    apiParams: {},
    // resultsMap: {},
  }))

  .views(self => ({
    get control() {
      return getRoot(self).search
    },

    get tenant() {
      return getRoot(self).tenant
    },

    get provider() {
      return searchInterfaces[self.type]
    },

    get category() {
      return self.control.selectedInformationCategory
    },

    filter(category) {
      const so = self.tenant.metaCategoryMap[category]?.searchOptions
      if (!so) return null

      // all or single search filter
      const filter = so.filter || so[self.id]?.filter
      if (filter) return buildFilter({ builder: self.type, filter })

      // raw filter
      return so[self.id]?.rawFilter
    },

    get filterMap() {
      const res = _.mapValues(self.tenant.metaCategoryMap, (v, k) => self.filter(k))
      return res
    },

    get activeFilters() {
      return self.control.activeCategoriesArray.map(c => self.filter(c))
    },

    get canLoadMore() {
      if (self.type === 'favourites') return false

      // loading for diary (total not returned in results)
      if (self.total === -1) return true
      if (self.total === -2) return false

      return self.total > self.results.length
    },

    hasResult(id) {
      return id in self.resultsMap
    },

    get resultsMap() {
      return _.keyBy(self.results, 'id')
    },

    get canAddFavourite() {
      return !!(self.control.favouritesSearch)
    },

    get locationZoomLevel() {
      const mc = tenantConfig.map
      const location = self.control.params.location
      const defaultZoom = mc?.locationZoom || 15

      if (!location || !mc.zoomLevels) return defaultZoom

      const zmap = mc.zoomLevels[location.type]
      if (!zmap) return defaultZoom

      return zmap[location.id] || defaultZoom
    },

    get mapParams() {
      const location = self.control.params.location
      const defaultLocation = tenantConfig.search?.location?.defaultLocation
      const selectedResult = self.control.selectedResult

      // no location

      if (!location) {
        return defaultLocation ? {
          center: { lat: defaultLocation.lat, lng: defaultLocation.lng },
          zoom: 15,
          selected: null,
        } : australia
      }

      // selected result

      if (selectedResult &&
        selectedResult.result.search === self.id &&
        selectedResult.result.address !== '--withheld--'
        ) {
        return {
          center: { lat: selectedResult.result.lat, lng: selectedResult.result.lng },
          zoom: 17,
          selected: selectedResult.result,
        }
      }

      // default

      return {
        center: { lat: location.lat, lng: location.lng },
        zoom: self.locationZoomLevel,
        selected: null,
      }
    },



    get combinedRawFilters() {
      const catFilters = self.control.activeCategoriesArray
        .map(c => self.tenant.metaCategoryMap[c])
        .map(cm => cm.searchOptions?.[self.id]?.rawFilter)
        .filter(f => !!f)

      return [{
        bool: {
          should: [
            self.defaultFilter,
            ...catFilters,
          ],
          minimum_should_match: 1,
        },
      }]
    },
  }))

  .actions(self => ({
    setDefaultFilter(filter) { self.defaultFilter = filter},
    setResults(results) { self.results = results },
    setLoading(loading) { self.loading = loading },
    setError(error) { self.error = error },

    clear() {
      self.loading = false
      self.error = false
      self.results.clear()
      // self.resultsMap.clear()
      self.total = 0
    },

    initSearch() {
      analytics.track('searchImplInit', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return

      self.clear()

      const to = self.tenant.options

      if (to.categoryMode === 'single' &&
        to.informationCategories &&
        !self.category.search) return

      if (!self.provider.buildParams) return
      if (!self.control.params.location) return

      if (!(self.id in queries)) return

      console.log(self.combinedRawFilters)

      const buildParams = self.provider.buildParams || buildQuery
      self.apiParams = buildParams({
        id: self.id,
        query: queries[self.id],
        params: {
          pageSize: self.pageSize,
          ...self.control.params,

          filters: self.activeFilters,
          categories: self.control.combinedCategories,
          tags: self.control.combinedTags,
          commissionedServices: self.control.combinedCommissionedServices,
          diaryFormat: self.control.combinedDiaryFormat,
        },
      })

      // self.apiParams = self.provider.buildParams({
      //   params: self.control.params,
      //   categories: self.control.combinedCategories,
      //   tags: self.control.combinedTags,
      //   commissionedServices: self.control.combinedCommissionedServices,
      //   pageSize: self.pageSize,
      // })

      self.performSearch()
    },

    tryAgain() {
      analytics.track('searchImplTryAgain', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return

      self.control.initSearch()
    },

    loadMore() {
      analytics.track('searchImplLoadMore', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return
      if (!self.provider.search) return
      if (!self.canLoadMore) return

      self.apiParams = {
        ...self.apiParams,
        PageSize: self.pageSize,
        PageNumber: (self.results.length / self.pageSize) + 1,
      }

      self.performSearch()
    },

    performSearch: flow(function* performSearch() {
      analytics.track('searchImplPerformSearch', {
        category: 'SearchImpl',
        label: self.id,
        searchParams: self.apiParams,
      })

      console.log('searchParams', self.apiParams)

      try {
        self.setLoading(true)

        const res = yield self.provider.search(self.apiParams)

        const resUnique = res.results.map(r => ({
          ...r,
          id: `${self.id}_${r.id}`,
          search: self.id,
        }))

        self.results = [...self.results, ...resUnique]

        analytics.track('searchImplPerformSearchResults', {
          category: 'SearchImpl',
          label: self.id,
          value: res.total,
        })

        if (res.total > 0) {
          self.total = res.total
        } else if (res.results.length < self.apiParams.PageSize) {
          self.total = -2
        } else {
          self.total = -1
        }
      } catch (error) {
        console.error(error)
        self.setError(true)
      } finally {
        self.setLoading(false)
      }
    }),

    // favourites

    insert(result) {
      if (self.hasResult(result.id)) return false

      self.results = [result, ...self.results]
      // self.resultsMap[result.id] = result

      return true
    },

    removeById(id) {
      if (!self.hasResult(id)) return false

      //
      if (self.control.selectedResult && self.control.selectedResult.id === id) {
        self.control.setSelected(null)
      }

      const ri = self.results.findIndex(r => r.id === id)
      self.results.splice(ri, 1)
      // delete self.resultsMap[id]

      return true
    },

  }))



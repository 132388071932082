import React from 'react'

import {
  Select,
  MenuItem,
} from '@material-ui/core'

const ParameterSelect = ({
  value = null,
  options = [],
  onChange = () => undefined,
  ...inputProps
}) => {

  const ival = value || 'null'

  const opts = [
    { value: 'null', label: 'N/A' },
    ...options.map(o => ({ value: o, label: o })),
  ]

  const handleChange = event => {
    const val = event.target.value !== 'null' ? event.target.value : null
    onChange(val)
  }

  return (
    <Select
      {...inputProps}
      value={ival}
      variant="outlined"
      onChange={handleChange}
    >
      {opts.map((o, i) => (
        <MenuItem key={i} value={o.value}>{o.label}</MenuItem>
      ))}
    </Select>
  )
}

export default ParameterSelect

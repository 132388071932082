import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import createSpacing from '@material-ui/core/styles/createSpacing'

const breakpoints = createBreakpoints({})
const spacing = createSpacing()

const colors = {
  primary: '#f7943e',
  secondary: '#4eb4be',
}

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f7943e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4eb4be',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#444',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'Helvetica Neue', 'Arial', 'sans-serif'],
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
      },
    },

    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.primary}`,
        },
      },
      input: {
        padding: '12px 12px',
      },
    },

  },
})

export default theme

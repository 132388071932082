import React from 'react'

import pull from 'lodash/pull'

import { makeStyles } from '@material-ui/core/styles'
import {
  // Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { resolveVariantText } from 'data/store/utils/survey-utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelRoot: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(1.1),

  },
  title: {
    marginBottom: theme.spacing(3),
  },
}))

const Interest = ({
  id,
  label,
  checked,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      // className={classes.label}
      classes={{
        root: classes.labelRoot,
        label: classes.label,
      }}
      control={
        <Checkbox
          name={id}
          color="primary"
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  )
}

const SurveyInterests = ({
  question,
  pathway,
  selected = [],
  onChange = () => undefined,
}) => {
  const classes = useStyles()
  const questionText = resolveVariantText(question, 'question', pathway)

  const isSelected = id => {
    if (!selected) return false
    return selected.findIndex(i => i === id) > -1
  }

  const handleChange = ({ target: { name, checked }}) => {
    if (!checked) {
      onChange(pull(selected, name))
    } else {
      onChange([...selected, name])
    }
  }

  return (
    <FormControl className={classes.root}>
      <FormLabel>{questionText}</FormLabel>
      <br />

      {question.interests.map(interest => (
        <Interest
          key={interest.id}
          id={interest.id}
          label={resolveVariantText(interest, 'question', pathway)}
          checked={isSelected(interest.id)}
          onChange={handleChange}
        />
      ))}

    </FormControl>
  )
}

export default SurveyInterests

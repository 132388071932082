import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

// import { toJS } from 'mobx'
import { useAnalytics } from 'use-analytics'
import { usePlatform } from 'ui/hooks/usePlatform'

import {
  LoadScriptNext as LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  // Circle
} from '@react-google-maps/api'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import GoogleMapsDataLayer from 'ui/components/atoms/GoogleMapsDataLayer'
import SearchResult from 'ui/components/molecules/SearchResult'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  result: {
    // width: 340,
    maxWidth: 340,
    padding: theme.spacing(1),
  },
  distance: {
    fontSize: 12,
    fontWeight: 300,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  category: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  address: {
    fontSize: 12,
    fontWeight: 400,
  },
}))

const SearchResultSmall = ({
  data,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Typography
        variant="body2"
        align="right"
        className={classes.distance}
        gutterBottom
      >
        {data.distanceLabel}
      </Typography>
      {/* <br /> */}
      <Typography
        variant="h1"
        className={classes.title}
      >
        {data.name}
      </Typography>
      <Typography
        variant="h2"
        className={classes.category}
        gutterBottom
      >
        {data.category}
      </Typography>

      <Typography
        variant="body1"
        className={classes.address}
        gutterBottom
      >
        {data.address}
      </Typography>
    </div>
  )
}


const SearchResultsMapInner = observer(({
  searchId,
  search,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const theme = useTheme()

  const smallResult = useMediaQuery(theme.breakpoints.down('xs'))

  const store = useStore()
  const { tenantConfig } = useConfig()
  const mapConfig = tenantConfig.map

  // const search = store.search.searches.get(searchId)
  const mapParams = search.mapParams
  const marker = tenantConfig.assets.mapMarkers?.[searchId] || tenantConfig.assets.mapMarker

  // const kmlUrl = 'https://mcdcdn.blob.core.windows.net/kml/ausstates/western_australia.kml'

  const kmlUrl = store.search.params.location?.kmlUrl
  const geoJsonUrl = !kmlUrl ? null : kmlUrl
    .replace(/devdirectorycdn/, 'mcdcdn')
    .replace(/\/kml\//, '/geojson-simple/')
    .replace(/\.kml$/, '.geojson')

  const handleClickMarker = (m) => () => {
    track('searchResultMapMarkerClick', {
      category: 'SearchResultsMap',
      label: m.name || 'unknown',
    })

    store.search.setSelected('map', m)
  }

  // const results = toJS(search.results)
  // console.log('search.results', results)

  const results = search.results.filter(r => r.address !== '--withheld--')

  return (
    <LoadScript
      id="gm-script-loader"
      googleMapsApiKey="AIzaSyCzdBpl0ariAPSDZK-nEpo5OrJ_7ThqhMM"
    >
      <GoogleMap
          id="gm-map"
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={mapParams.zoom}
          center={mapParams.center}
          options={{
            scrollwheel: true,
            styles: tenantConfig.map.style,
            // disableDefaultUI: true,
            fullscreenControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            zoomControl: false,
          }}
        >
          {mapConfig?.locationBoundary?.show &&
            <GoogleMapsDataLayer url={geoJsonUrl} options={mapConfig?.locationBoundary} />
          }

          {results.map((m, i) => (
            <Marker
              key={i}
              position={m.geometry}
              // icon={`/images/marker-${icons[m.type]}.png`}
              icon={marker}
              title={m.name}
              onClick={handleClickMarker(m)}
            >
              {mapParams.selected && mapParams.selected.id === m.id && (
                <InfoWindow
                  position={store.search.selectedResult.geometry}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -4),
                  }}
                >
                  <div className={classes.result}>
                    {smallResult ? (
                      <SearchResultSmall data={m} />
                    ) : (
                      <SearchResult
                        search={search}
                        data={m}
                        variant="map"
                      />
                    )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}

        </GoogleMap>
    </LoadScript>
  )
})

const SearchResultsMap = observer(({ searchId }) => {
  const store = useStore()
  const search = store.search.searches.get(searchId)
  const platform = usePlatform()

  useEffect(() => {
    if (platform.isBot) console.log('Bot detected, disabling googlemaps')
  }, [platform.isBot])

  if (platform.isBot) return null

  return <SearchResultsMapInner {...{ searchId, search }} />
})

export default SearchResultsMap

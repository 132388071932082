import React from 'react'

import clsx from 'clsx'
import sortBy from 'lodash/sortBy'
import dropRightWhile from 'lodash/dropRightWhile'
import take from 'lodash/take'

import { useQuery } from 'react-query'
import {
  useRouteMatch,
  Link as ReactRouterLink,
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import {
  getStates,
  getCouncilsForState,
  getLocalitiesForCouncil,
  getLocalityByParams,
} from 'data/api/cie-api'

import { RouterLink } from 'ui/components/atoms/Link'

import { useMergeOptions } from 'ui/hooks/useMergeOptions'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    margin: '40px auto',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },
  actionButton: {
    margin: '20px auto',
  },
  items: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  regionLink: {
    textTransform: 'none',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  regionLinkText: {
    flex: '1 0 auto',
  },
  regionLinkIcon: {
    flex: '0 1 auto',
    maxWidth: 32,
    marginRight: 4,
  },
}))

const linkIcons = {
  browse: 'fas fa-chevron-right',
  search: 'fas fa-search',
}

const RegionLink = ({ item }) => {
  const classes = useStyles()
  const icon = linkIcons[item.type]

  return (
    <Button
      className={classes.regionLink}
      component={ReactRouterLink}
      variant="outlined"
      color="primary"
      to={item.to}
    >
      <div className={classes.regionLinkText}>{item.name}</div>
      <i className={clsx(classes.regionLinkIcon, icon)} />
    </Button>
  )
}

const Title = ({ title }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <br />
    </>
  )
}

const Items = ({ items }) => {
  const classes = useStyles()

  return (
    <div className={classes.items}>
      <Grid container spacing={1}>
        {items.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} lg={4}>
            {/* <RouterLink to={item.to}>{item.name}</RouterLink> */}
            <RegionLink item={item} />
          </Grid>
        ))}

      </Grid>
    </div>
  )
}

const RegionBreadcrumbs = ({ options, state, council, locality }) => {

  const paths = dropRightWhile([options.regionPrefix, state, council, locality], o => !o)

  const crumbs = paths.map((v, i, arr) => {
    return {
      to: '/' + take(arr, i + 1).join('/'),
      label: v === 'regions' ? 'Regions' : v.replaceAll('_', ' '),
    }
  })

  const Crumb = ({ current, to, label }) => {
    if (current) return <Typography>{label}</Typography>
    else return <RouterLink to={to}>{label}</RouterLink>
  }

  if (crumbs.length < 2) return null

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="›"
      >
        {crumbs.map((c, i) => (
          <Crumb key={i} {...c} current={i === crumbs.length - 1} />
        ))}

      </Breadcrumbs>
      <br />
    </>
  )
}

const levels = {
  undefined: {
    queryKey: () => (['undefined']),

    lookup: async () => {
      console.error('lookup is undefined')
    },
  },
  states: {
    queryKey: () => (['states']),

    lookup: async ({ options }) => {
      var res = await getStates()
      if (!res.states) throw new Error()

      return {
        title: 'States',
        items: sortBy(res.states.map(s => ({
          type: 'browse',
          name: s.name,
          to: `/${options.regionPrefix}/${s.longUrl}`,
        })), ['name']),
      }
    },
  },
  councils: {
    queryKey: ({ state }) => (['states', { state }]),

    lookup: async ({ options, state }) => {
      var res = await getCouncilsForState({ state })
      if (!res.councils) return null

      return {
        title: `Councils in ${res.state.name}`,
        items: sortBy(res.councils.map(c => ({
          type: 'browse',
          name: c.displayNameCouncil,
          to: `/${options.regionPrefix}${c.url}`, // inconsistent!!
        })), ['name']),
      }
    },
  },
  localities: {
    queryKey: ({ state, council }) => (['localities', { state, council }]),

    lookup: async ({ options, state, council }) => {
      var res = await getLocalitiesForCouncil({ state, council })
      if (!res.localities) return null

      return {
        title: `Suburbs in ${res.council.displayNameCouncil}, ${res.state.name}`,
        items: sortBy(res.localities.map(l => ({
          type: 'browse',
          name: `${l.locationName} ${l.postcode}`,
          to: `/${options.regionPrefix}/${l.stateUrl}/${l.councilUrl}/${l.url}`,
        })), ['name']),
      }
    },
  },
  categories: {
    queryKey: ({ state, council, locality })  => (['categories', { state, council, locality }]),

    lookup: async ({ options, state, council, locality, categories }) => {
      var res = await getLocalityByParams({ state, council, locality })
      if (!res) return null

      const stateName = res.stateUrl.replaceAll('_', ' ')

      return {
        title: `Health Services in ${res.name}, ${stateName}`,
        defaultItems: [
          {
            type: 'search',
            name: `All Health Services`,
            to: `/${options.searchPrefix}/${res.stateUrl}/${res.councilUrl}/${res.url}`,
          },
        ],
        items: sortBy(categories.map(c => ({
          type: 'search',
          name: c.title,
          to: `/${options.searchPrefix}/${res.stateUrl}/${res.councilUrl}/${res.url}/${options.categoryPrefix}/${c.url}`,
        })), ['name']),
      }
    },
  },
}

const levelKeyForParams = ({ state, council, locality }) => {
  if (!state) return 'states'
  if (state && !council) return 'councils'
  if (state && council && !locality) return 'localities'
  if (state && council && locality) return 'categories'
  return 'undefined'
}

const defaultRegionsOptions = {
  regionPrefix: 'regions',
  searchPrefix: 'search',
  categoryPrefix: 'category',
}

const Regions = ({ options }) => {
  const opts = useMergeOptions(defaultRegionsOptions, options)

  const classes = useStyles()
  const store = useStore()
  const match = useRouteMatch()
  const levelKey = levelKeyForParams(match.params)
  const level = levels[levelKey]

  const { isLoading, data, error, refetch } = useQuery(
    level.queryKey(match.params),
    () => level.lookup({
      options: opts,
      ...match.params,
      categories: store.tenant.categories,
    })
  )

  return (
    <div className={classes.root}>

      <RegionBreadcrumbs
        options={opts}
        {...match.params}
      />


      {isLoading &&
        <CircularProgress
          size={32}
          thickness={3.0}
          className={classes.progress}
        />
      }

      {!isLoading && error &&
        <div className={classes.actionContainer}>
          <Typography variant="body1">There was an error fetching results.</Typography>
          <Button
            className={classes.actionButton}
            variant="outlined"
            onClick={refetch}
          >
            Try Again
          </Button>
        </div>
      }

      {!isLoading && data &&
        <>
          {data.title && <Title title={data.title} />}
          {data.defaultItems &&
            <>
              <Items items={data.defaultItems} />
              <br />
            </>
          }
          {data.items && <Items items={data.items} />}
        </>
      }
    </div>
  )
}

export default Regions

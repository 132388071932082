import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
} from '@material-ui/core'

import SearchInput from 'ui/components/molecules/SearchInput'
import ParameterSelect from 'ui/components/atoms/ParameterSelect'
import LocationInput from 'ui/components/molecules/LocationInput'
import GeographySelect from 'ui/components/atoms/GeographySelect'
import RadiusSlider from 'ui/components/atoms/RadiusSlider'
import DateRangeParam from 'ui/components/atoms/DateRangeParam'
import LabelSwitch from 'ui/components/atoms/LabelSwitch'
import CategoryButtons from 'ui/components/atoms/CategoryButtons'
import IconLabel from 'ui/components/atoms/IconLabel'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { useMergeOptions } from 'ui/hooks/useMergeOptions'

import CategorySelect from '../atoms/CategorySelect'

const { tenantConfig } = useConfig()

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  vertical: {
    padding: theme.spacing(3),
    paddingBottm: theme.spacing(5),
  },
}))

const Parameter = observer(({ parameterGroup: pg, control, param }) => {
  const { track } = useAnalytics()

  const store = useStore()
  const params = store.search.params
  const ui = store.search.ui

  const handleChange = value => {
    track('searchParamaterChange', {
      category: 'Search',
      label: param.id,
    })

    store.search.params.set(param.id, value)
  }

  const handleCategoriesChange = selected => {
    track('searchParamaterChange', {
      category: 'Search',
      label: 'selectedCategories',
    })

    params.setSelectedCategories(selected)
  }

  const handleShowAllCategories = value => {
    track('categoriesToggleAllCategories', {
      category: 'Search',
      label: value ? 'Show' : 'Hide',
    })

    store.search.setShowAllCategories(value)
  }

  const type =
    ('searchParamaterType' in param)
    ? param.searchParamaterType
    : 'type' in param
    ? param.type
    : null

  const paramControl = () => {
    switch (type) {
      case 'query': return (
        <SearchInput
          value={params[param.id]}
          onChange={handleChange}
          {...control.props}

          fullWidth
        />
      )

      case 'location': return (
        <LocationInput
          value={params[param.id]}
          onChange={handleChange}
          fullWidth
        />
      )

      case 'geographySelect': return (
        <GeographySelect
          paramConfig={param}
          value={toJS(params[param.id])} // why??
          onChange={handleChange}
          fullWidth
        />
      )

      case 'radius': return (
        <RadiusSlider
          value={params[param.id]}
          onChange={handleChange}
          fullWidth
        />
      )

      case 'dateRange': return (
        <DateRangeParam
          value={params[param.id]}
          onChange={handleChange}
          fullWidth
        />
      )

      case 'categoryButtons': return (
        <>
          {store.search.params.categories.length > 0 && (
            <LabelSwitch
              checked={ui.showAllCategories}
              onChange={handleShowAllCategories}
              label="Show all categories"
            />
          )}

          <CategoryButtons
            selectMode={tenantConfig.options.categoryMode}
            categories={params.visibleCategories}
            selected={params.selectedCategories}
            onChange={handleCategoriesChange}
            fullWidth
          />
        </>
      )

      case 'categorySelect': return (
        <CategorySelect
          selectMode={tenantConfig.options.categoryMode}
          categories={params.visibleCategories}
          selected={params.selectedCategories}
          onChange={handleCategoriesChange}
          fullWidth
        />
      )

      case 'select': return (
        <ParameterSelect
          value={params[param.id]}
          options={param.options}
          onChange={handleChange}
          fullWidth
        />
      )

      default:
        return <div>{type} {JSON.stringify(param, null, 2)}</div>
    }
  }

  const ctrl = paramControl()

  return (
    <>
      {pg?.options?.iconLabel && <IconLabel label={param.label} iconClasses={param.icon} />}
      {ctrl}
    </>
  )

})

const HorizontalGrid = ({ parameterGroup: pg, params }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >

      {params.map((p, i) => (
        <Grid
          key={i}
          item
          xs={pg?.controls[i]?.size || pg?.options?.defaultSize || 12}
        >
          <Parameter
            parameterGroup={pg}
            control={pg.controls[i]}
            {...p}
          />
        </Grid>
      ))}

    </Grid>
  )
}

const VerticalGrid = ({ parameterGroup: pg, params }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.vertical}
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >

      {params.map((p, i) => (
        <Grid item xs={p.size || pg?.options?.defaultSize || 12} key={i}>
          <Parameter
            parameterGroup={pg}
            control={pg.controls[i]}
            {...p}
          />
        </Grid>
      ))}

    </Grid>
  )
}

const defaultParameterGroups = {
  options: {
    type: 'vertical',
    defaultSize: 12,
    iconLabel: true,
  },
  controls: [],
}

const SearchParameters = observer(({
  parameterGroup,
}) => {
  const opts = useMergeOptions(defaultParameterGroups, parameterGroup)
  const paramConfigs = tenantConfig.search?.parameters || {}

  const params = opts.controls
      .filter(c => c.param in paramConfigs)
      .map(c => ({ param: paramConfigs[c.param] }))

  if (params.length === 0) return null

  if (opts.options.type === 'toolbar') return (
    <HorizontalGrid
      parameterGroup={opts}
      params={params}
    />

  )

  return (
    <VerticalGrid
      parameterGroup={opts}
      params={params}
    />
  )
})

export default SearchParameters

import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core'

import LocationInput from 'ui/components/molecules/LocationInput'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      minWidth: 500,
    },
  },
}))

const LocationDialog = observer(({
  open,
  onClose = () => undefined,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const { tenantConfig } = useConfig()
  const store = useStore()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [location, setLocation] = useState(null)

  const defaultLocation = tenantConfig.search?.location?.defaultLocation
  const defaultLocationLabel = tenantConfig.search?.location?.defaultLocationLabel || defaultLocation?.label || null

  const handleChangeLocation = value => {
    setLocation(value)
  }

  const handleOk = () => {
    track('searchLocationButtonClick', {
      category: 'Search',
      label: 'Ok',
    })

    store.search.params.setLocation(location)
    onClose()
  }

  const handleDefault = () => {
    track('searchLocationButtonClick', {
      category: 'Search',
      label: 'Use Default',
    })

    store.search.params.setLocation(defaultLocation)
    onClose()
  }

  return (
    <Dialog
      classes={{ paper: classes.root }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      disableBackdropClick
    >
      <DialogTitle id="responsive-dialog-title">
        {"Where are you?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>To search the directory, you need to provide a location.</span>
          {defaultLocation && <span>If you do not wish to provide one, the default location ({defaultLocationLabel}) will be selected.</span>}
        </DialogContentText>

        <Box my={4}>
          <LocationInput
            id="location-select-dialog"
            value={location}
            onChange={handleChangeLocation}
            buttonLabel
          />
        </Box>
        {/* <SurveyLocation
          defaultLocation={tenantConfig.options.defaultLocation}
          mode="survey"
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          autoFocus
        >
          Cancel
        </Button>

        {defaultLocation &&
          <Button
            onClick={handleDefault}
            color="primary"
            autoFocus
          >
            Use Default
          </Button>
        }

        <Button
          autoFocus
          onClick={handleOk}
          disabled={location === null}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default LocationDialog

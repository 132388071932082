import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  radioGroupRow: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

const SurveyRadioGroup = ({
  name,
  value,
  label,
  // help,
  options,
  valueField = null,
  labelField = null,
  deselect = false,
  row = false,
  onChange = () => undefined,
}) => {
  const classes = useStyles()

  const handleRadioChange = event => {
    const nv = deselect && event.target.value === value ? null : event.target.value
    onChange(nv)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel>{label}</FormLabel>
      <br />
      <RadioGroup
        className={row ? classes.radioGroupRow : null}
        name={name}
        value={value}
      >
        {options.map((o, i) => (
          <FormControlLabel
            key={i}
            value={valueField ? o[valueField] : o}
            label={labelField ? o[labelField] : o}
            control={<Radio onClick={handleRadioChange} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SurveyRadioGroup

import React, { useMemo, useEffect, useState } from 'react'

import { toJS } from 'mobx'
import { isAlive, isStateTreeNode } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import clsx from 'clsx'
import debounce from 'lodash/throttle'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { getLocationSuggestions, getLocation } from 'data/api/cie-api'
import useDeepCompareEffect from 'use-deep-compare-effect'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.3, 1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ced4da',
      borderRightWidth: 0,
      borderRadius: '4px 0 0 4px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ced4da',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
  },
  autoComplete: {
    flex: '1 1 auto',
  },
  formGroup: {
    borderRadius: 0,
    border: '1px solid #f00',
    backgroundColor: '#f0f',
    padding: '10px 12px',
  },
  locateMeSmall: {
    flex: '0 1 auto',
    borderRadius: '0 4px 4px 0',
  },
  locateMeLarge: {
    flex: '0 1 auto',
    borderRadius: '0 4px 4px 0',
    padding: theme.spacing(1, 3),

  },
  buttonIcon: {
    // fontSize: 16,
    // marginRight: theme.spacing(1),
  },
  buttonLabel: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const locIcons = {
  default: 'fas fa-location-arrow',
  busy: 'far fa-circle-notch fa-spin',
}

const LocationInput = observer(({
  id = 'location-select',
  disabled = false,
  value = null,
  onChange = () => undefined,
  buttonLabel = false,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const [error, setError] = useState(null)
  const [locIcon, setLocIcon] = useState(locIcons.default)
  const [internalValue, setInternalValue] = useState(value)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  // const loaded = useRef(false)

  const fetchLocations = useMemo(() => debounce(async (query) => {
    const res = await getLocationSuggestions(query)
    setOptions(res)
  }, 400), [])

  // update value
  // TODO: doesn't trigger on replace (hack in SurveyLocation)

  useDeepCompareEffect(() => {
    // console.log('valueChanged', toJS(value))

    const v = isStateTreeNode(value) ? toJS(value) : value

    setError(null)
    setInputValue(v ? v.label : '')
    setInternalValue(v)
    setOptions(v ? [v] : [])
  }, [toJS(value) || {}])

  // update input

  useEffect(() => {
    // console.log('inputValue', inputValue)
    if (value && value.default) return
    if (inputValue.length < 2) return
    fetchLocations(inputValue)
  }, [value, inputValue, fetchLocations])


  const fetchLocation = async ({ lat, lng}) => {
    const loc = await getLocation({ lat, lng })
    setLocIcon(locIcons.default)

    if (!loc) return

    const myLoc = { ...loc, lat, lng }

    setInternalValue(myLoc)
    onChange(myLoc)
  }

  const locateMe = async () => {

    track('locationInputGeolocate', {
      category: 'Location',
      label: 'Locate Me',
    })

    setLocIcon(locIcons.busy)

    navigator.geolocation.getCurrentPosition(
      pos => {
        fetchLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude })
      },
      error => {
        track('locationInputGeolocateError', {
          category: 'Location',
          label: 'Error',
        })

        console.log(error)
        setError('Your location could not be determined.')
        setLocIcon(locIcons.default)
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    )
  }

  const getOptionLabel = (option) => {
    if (!option) return ''
    if (isStateTreeNode(option) && !isAlive(option)) return ''
    if (typeof option === 'string') return option
    return 'label' in option ? option.label : null
  }

  const getOptionSelected = (option, value) => {
    if (!option || !value) return false

    // console.log(toJS(option), toJS(value))

    if (isStateTreeNode(option) && !isAlive(option)) return false
    if (isStateTreeNode(value) && !isAlive(value)) return false

    return option.id === value.id
  }

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue)
  }

  const handleChange = (_, newValue) => {
    track('locationInputSelect', {
      category: 'Location',
      label: newValue?.label || 'null',
    })

    setInternalValue(newValue)
    onChange(newValue)
  }

  return (
    <div className={classes.root}>
      {/* {value &&
        <div>{value.label}</div>
      } */}
      <div className={classes.controls}>
        <Autocomplete
          id={id}
          disabled={disabled}
          classes={{
            root: classes.autoComplete,
            inputRoot: classes.inputRoot,
          }}
          freeSolo={true}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          filterOptions={(x) => x}
          autoComplete
          // includeInputInList
          // filterSelectedOptions
          inputValue={inputValue}
          value={internalValue}
          // options={options}
          options={options}
          onChange={handleChange}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Suburb"
              variant="outlined"
              fullWidth
            />
          )}
          renderOption={(option) => {
            if (!option) return null
            return (
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body1" color="textSecondary">
                    {option.label}
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
          fullWidth
        />
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          className={buttonLabel ? classes.locateMeLarge : classes.locateMeSmall}
          onClick={locateMe}
          disableElevation
        >
          <i className={clsx(locIcon, classes.buttonIcon)} />

          {buttonLabel && (
            <div className={classes.buttonLabel}>Locate Me</div>
          )}
        </Button>
      </div>

      {error &&
        <Box my={2} p={1}>
          <Typography variant="body2">
            <i className="far fa-exclamation-triangle" />&nbsp;{error}
          </Typography>
        </Box>
      }
    </div>
  )
})

export default LocationInput

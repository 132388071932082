import React, { useState, useEffect } from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import {
  InputAdornment,
  IconButton,
  // Input,
  OutlinedInput,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
  searchIcon: {
    color: '#666',
  },
}))

const SearchInput = ({
  value = '',
  onChange = () => undefined,
  showSearchIcon = false,
  ...inputProps
}) => {
  const classes = useStyles()
  const [tempValue, setTempValue] = useState('')

  useEffect(() => {
    setTempValue(value)
  }, [value])

  return (
    <OutlinedInput
      {...inputProps}
      value={tempValue}
      onChange={e => setTempValue(e.target.value)}
      onKeyPress={e => {
        if (e.charCode === 13) {
          onChange(tempValue)
          document.activeElement.blur()
        }
      }}
      startAdornment={showSearchIcon &&
        <InputAdornment position="start">
          <i className={clsx('far fa-search', classes.searchIcon)} />
        </InputAdornment>
      }
      endAdornment={tempValue.length > 0 &&
        <InputAdornment position="end">
          <IconButton
            tabIndex={-1}
            aria-label="clear input"
            onClick={() => {
              setTempValue('')
              onChange('')
            }}
            edge="end"
          >
            <CloseIcon />

            {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default SearchInput

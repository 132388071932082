// location -> location
// age -> range
// gender -> string
// atsi -> string
// lgbtqi -> string

// metacategory -> string[]

// kessler k-10
// kessler k-5

export const surveyShared = {
  questions: [
    {
      id: 'query',
      type: 'query',
      label: 'Search',
      icon: 'far fa-search',
      question: {
        self: 'What are you looking for?',
        other: 'What are they looking for?',
      },
    },
    {
      id: 'location',
      type: 'location',
      label: 'Location',
      icon: 'fas fa-map-marker-question',
      question: {
        self: 'Where are you?',
        other: 'Where are they?',
      },
      help: {
        self: 'Knowing what suburb or area code you are in will help us find services nearby.',
        other: 'Knowing what suburb or area code they are in will help us find services nearby.',
      },
    },
    {
      id: 'radius',
      type: 'radius',
      label: 'Radius',
      icon: 'far fa-running',
      question: {
        self: 'How far will you travel?',
        other: 'How far will they travel?',
      },
    },
    {
      id: 'dateRange',
      type: 'dateRange',
      label: 'Appointment Dates',
      icon: 'far fa-clock',
      question: 'When?',
    },
    {
      id: 'age',
      type: 'radio',
      searchParamaterType: 'select',
      label: 'Age',
      question: {
        self: 'I am aged between:',
        other: 'They are aged between:',
      },
      options: ['0 - 15', '16 - 25', '26 - 40', '41 - 64', '65+'],
      queryMap: {
        '0 - 15': { min: 0, max: 15 },
        '16 - 25': { min: 16, max: 25 },
        '26 - 40': { min: 26, max: 40 },
        '41 - 64': { min: 41, max: 64 },
        '65+': { min: 65, max: 999 },
      },
    },
    {
      id: 'gender',
      type: 'radio',
      searchParamaterType: 'select',
      label: 'Gender',
      question: {
        self: 'I am:',
        other: 'They are:',
      },
      options: ['Male', 'Female', 'Intersex', 'Transgender'],
    },
    {
      id: 'atsi',
      type: 'radio',
      searchParamaterType: 'select',
      label: 'Ethnicity',
      question: {
        self: 'Do you identify as Aboriginal or Torres Strait Islander?',
        other: 'Do they identify as Aboriginal or Torres Strait Islander?',
      },
      options: ['Yes', 'No', 'Unsure'],
    },
    {
      id: 'sexuality',
      type: 'radio',
      searchParamaterType: 'select',
      label: 'Sexuality',
      question: {
        self: 'Do you identify as LGBTIQI+?',
        other: 'Do they identify as LGBTIQI+?',
      },
      options: ['Yes', 'No', 'Unsure'],
    },
    {
      id: 'k10',
      type: 'scaleeval',
      label: 'k-10',
      options: [
        { value: 1, label: 'Not at all'},
        { value: 2, label: 'A little of the time'},
        { value: 3, label: 'Some of the time'},
        { value: 4, label: 'Most of the time'},
        { value: 5, label: 'All of the time'},
      ],
      summary: 'In the past 4 weeks:',
      questions: [
        {
          label: 'About how often did you feel tired out for no good reason?',
        },
        {
          label: 'About how often did you feel nervous?',
        },
        {
          label: 'About how often did you feel so nervous that nothing could calm you down?',
          followPrevious: true,
        },
        {
          label: 'About how often did you feel hopeless?',
        },
        {
          label: 'About how often did you feel restless or fidgety?',
        },
        {
          label: 'About how often did you feel so restless you could not sit still?',
          followPrevious: true,
        },
        {
          label: 'About how often did you feel depressed?',
        },
        {
          label: 'About how often did you feel that everything is an effort?',
        },
        {
          label: 'About how often did you feel so sad that nothing could cheer you up?',
        },
        {
          label: 'About how often did you feel worthless?',
        },
      ],
      scoring: [
        { id: 'low', score: [0, 9] },
        { id: 'early', score: [10, 19] },
        { id: 'mild', score: [20, 24] },
        { id: 'moderate', score: [25, 29] },
        { id: 'severe', score: [30, 50] },
      ],
    },
    {
      id: 'k5',
      type: 'scaleeval',
      label: 'k-5',
      options: [
        { value: 1, label: 'Not at all'},
        { value: 2, label: 'A little of the time'},
        { value: 3, label: 'Some of the time'},
        { value: 4, label: 'Most of the time'},
        { value: 5, label: 'All of the time'},
      ],
      summary: 'In the past 4 weeks:',
      questions: [
        {
          label: 'About how often did you feel nervous?',
        },
        {
          label: 'About how often did you feel without hope?',
        },
        {
          label: 'About how often did you feel restless or jumpy?',
        },
        {
          label: 'About how often did you feel that everything is an effort?',
        },
        {
          label: 'About how often did you feel so sad that nothing could cheer you up?',
        },
      ],
      scoring: [
        { id: 'low', score: [0, 4] },
        { id: 'early', score: [5, 9] },
        { id: 'mild', score: [10, 12] },
        { id: 'moderate', score: [13, 14] },
        { id: 'severe', score: [15, 25] },
      ],
    },
  ],
}

import React, { useState } from 'react'

// import clsx from 'clsx'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import { useAnalytics } from 'use-analytics'

import { makeStyles, withStyles } from '@material-ui/styles'
import {
  SwipeableDrawer,
} from '@material-ui/core'

import Headers  from 'ui/components/molecules/Headers'
import MainAppBar from 'ui/components/organisms/MainAppBar'
import MainMenu from 'ui/components/molecules/MainMenu'
// import NotFound from 'ui/components/molecules/NotFound'
import TenantRoutes from 'ui/components/molecules/TenantRoutes'

import { useConfig } from 'config/config'
import { useResizeDetect } from 'ui/hooks/useResizeDetect'
import { useAppAnalytics } from 'ui/hooks/useAppAnalytics'
import { props } from 'lodash/fp'

// const bottomOffset = 0


// prevent ios full window scroll from touch
const preventDefault = e => e.preventDefault()

window.addEventListener('touchmove', preventDefault, {
  passive: false,
})

// window.removeEventListener('touchmove', preventDefault)

const styles = theme =>({
  root: {
    backgroundColor: '#fff',
    maxHeight: props => props.height,
    height: props => props.height,

    maxWidth: '100vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    flex: '0 1 auto',
  },
  content: {
    flex: `1 0 0`,
    overflow: 'hidden',
  },
})


const App = withStyles(styles, { name: 'AMSApp' })(props => {

  const { classes } = props

  const { tenantConfig } = useConfig()
  const { track } = useAnalytics()

  const [mainMenu, setMainMenu] = useState(false)

  const handleClickMenu = () => {
    track('mainMenuToggle', {
      category: 'Navigation',
      label: 'Main Menu',
    })
    toggleMenu(!mainMenu)()
  }

  const toggleMenu = open => () => {
    setMainMenu(open)
  }

  // hooks have to go under router

  const AppAnalytics = () => {
    useAppAnalytics()
    return null
  }

  return (
    <Router>
      {/* <Headers /> */}
      <AppAnalytics />
      <div className={classes.root}>
        <MainAppBar
          options={tenantConfig.appBar}
          className={classes.appBar}
          onMenuClick={handleClickMenu}
        />
        <div className={classes.content}>
          <TenantRoutes />
        </div>
      </div>
      <SwipeableDrawer
        anchor="left"
        open={mainMenu}
        onOpen={toggleMenu(true)}
        onClose={toggleMenu(false)}
      >
        <MainMenu
          items={tenantConfig.menu}
          onToggleMenu={toggleMenu}
        />
      </SwipeableDrawer>
    </Router>
  )
})

const AppContainer = () => {
  const { height } = useResizeDetect()

  return <App height={height - 1} />
}

export default AppContainer

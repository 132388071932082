/* eslint-disable no-unused-vars */
/* global google */
import React, { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { useQuery } from 'react-query'

const getGeoJson = async url => {
  const data = await fetch(url)
  const json = await data.json()
  return json
}

const GoogleMapsDataLayer = ({
  options,
  url,
}) => {
  const map = useGoogleMap()

  const purl = url?.match(/.*\.(geojson|json)$/) ? url : null

  const { data: geoJsonData } = useQuery(
    ['geojson-data', purl],
    () => getGeoJson(purl),
    { cacheTime: 'Infinity', refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (!map && !options?.layerStyle) return
    map.data.setStyle(options?.layerStyle)
  }, [map, options.layerStyle])

  useEffect(() => {
    if (!map) return

    // clearData()
    map.data.forEach(feature => map.data.remove(feature))

    if (!geoJsonData) return

    map.data.addGeoJson(geoJsonData)
  }, [map, geoJsonData])

  return null
}

export default GoogleMapsDataLayer

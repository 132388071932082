import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

import DesktopSearch from '../organisms/DesktopSearch'
import MobileSearch from '../organisms/MobileSearch'

import SearchRouteHandler from '../molecules/SearchRouteHandler'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
}))


const Search = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <SearchRouteHandler />

      {isMobile ? <MobileSearch /> : <DesktopSearch />}

      {/* <div className={classes.root}>
        {isMobile ? (
          <MobileSearch />
        ) : (
          <DesktopSearch />
        )}
      </div> */}
    </>
  )
}

export default Search

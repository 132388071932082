import humps from 'lodash-humps'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'

import { client } from './cie-api'

const toGeography = mg => {
  return {
    type: 'geography',
    id: mg.geographyId,
    label: mg.title,
    dataset: mg.dataset,
    lat: mg.centralLatitude,
    lng: mg.centralLongitude,
    area: mg.area,
    kmlUrl: mg.kmlLocation,
  }
}

export const getGeographyById = async id => {
  const result = await client.get(`/system/geographies/id/${id}`)

  if (!result.data?.data || !result.data?.success) return null

  return toGeography(humps(result.data.data))
}

export const getGeographyByDataset = async dataset => {
  const result = await client.get(`/system/geographies/${dataset}`)

  if (!result.data?.data || !result.data?.success) return null

  return humps(result.data.data).map(toGeography)
}

// select

const selectFetches = {
  unmapped: async source => {
    console.error('unmaped selectFetch', source)
  },
  geography: async source => {
    const res = await getGeographyById(source.id)
    return [{
      ...res,
      label: source.label || res.label,
      default: source.default,
    }]
  },
  dataset: async source => {
    const res = await getGeographyByDataset(source.id)
    const ress = orderBy(res, ['label'], ['asc'])
    return ress
  },
}

export const getGeographySelectOptions = async paramConfig => {
  const sourceFetches = paramConfig.sources.map(s => {
    const fn = s.type in selectFetches ? selectFetches[s.type] : selectFetches.unmapped
    return fn(s)
  })

  const allResults = flatten((await Promise.all(sourceFetches)).filter(x => x))
  return allResults
}

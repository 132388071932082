import { types } from 'mobx-state-tree'

export const Geography = types
  .model({
    type: types.literal('geography'),
    id: 0,
    default: false,
    label: '',
    dataset: '',
    area: 0.0,
    lat: 0.0,
    lng: 0.0,
    kmlUrl: '',
  })

import React, { useState } from 'react'

import platform from 'platform'
import isbot from 'isbot'

export const usePlatform = () => {

  const buildState = platform => ({
    platform,
    isBot: isbot(platform.ua),
  })

  const [val] = useState(buildState(platform))
  return val
}

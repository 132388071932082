import { addDays } from 'date-fns'

// default mappings

const paramMaps = {
  age: {
    values: {
      '0 - 15': { ageMin: 0, ageMax: 15 },
      '16 - 25': { ageMin: 16, ageMax: 25 },
      '26 - 40': { ageMin: 26, ageMax: 40 },
      '41 - 64': { ageMin: 41, ageMax: 64 },
      '65+': { ageMin: 65, ageMax: 999 },
    },
    default: { AgeMin: 0, AgeMax: 999 },
  },

  gender: {
    values: {
      'Male': 'MaleOnly',
      'Female': 'FemaleOnly',
    },
    default: null,
  },
}

const mapParam = (param, value) => {
  const m = paramMaps[param]
  if (!m) {
    console.warn(`No param mapping found for "${param}"`)
    return
  }

  return m.values[value] || m.default
}


// builders

const builders = {
  default: {
    page: ({ pageNo = 1, pageSize = 20 }) => {
      return { pageNo, pageSize }
    },

    sort: ({ sortField, sortDirection }) => {
      // return { sortField: param.sortField}
      return { sortField, sortDirection }
    },

    query: ({ query }) => {
      return { searchTerm: query || '' }
    },

    categories: ({ categories }) => {
      return { categories: categories, categoriesRequired: true }
    },

    commissionedServices: ({ commissionedServices }) => {
      const cs = commissionedServices.length > 0 ? commissionedServices : [-1]
      return { commissionedServices: cs }
    },

    location: ({ location }) => {
      return { latitude: location.lat, longitude: location.lng }
    },

    locationRegion: ({ location }) => {
      if (location.type !== 'geography') console.warn('Location is not geography type', location)
      return { locationRegions: [location.id] }
    },

    serviceRegions: ({ queryBody, location }) => {
      if (location.type !== 'geography') console.warn('Location is not geography type', location)
      // return { locationRegions: [location.id] }

      return {
        extendedFilters: [
          ...(queryBody.extendedFilters || []),
          {
            nested: {
              path: 'serviceRegions',
              query: {
                term: {
                  'serviceRegions.geographyId': location.id,
                },
              },
            },
          },
        ],
      }
    },

    radius: ({ radius }) => {
      return { radius }
    },

    age: ({ age }) => {
      return { age: mapParam('age', age) }
    },

    gender: ({ gender }) => {
      return { gender: mapParam('gender', gender) }
    },

    filters: ({ queryBody, filters }) => {
      return {
        extendedFilters: [
          ...(queryBody.extendedFilters || []),
          {
            bool: {
              should: [
                { terms: { categories: [-1] } },
                ...filters,
              ],
              minimum_should_match: 1,
            },
          },
        ],
      }
    },

    rawFilters: ({ rawFilters }) => {
      return { extendedFilters: rawFilters }
    },
  },

  diaryHealth: {
    page: ({ pageNo = 1, pageSize = 20 }) => {
      return { pageNumber: pageNo, pageSize: pageSize }
    },

    query: ({ query }) => {
      return { whatText: query || '' }
    },

    categories: ({ categories }) => {
      return { serviceCategories: categories }
    },


    locationId: ({ location }) => {
      if (location.type !== 'locality') console.warn('Location is not locality type', location)
      return { locationId: [location.id] }
    },

    radius: ({ radius }) => {
      return { radiusKM: radius}
    },

    dateRange: ({ dateRange }) => {
      if (dateRange.delta < 0) {
        const from = dateRange.start || new Date()
        const to = dateRange.end ? addDays(dateRange.end, 1) : addDays(from, 90)

        return {
          whenIndex: 'Custom',
          searchFrom: from.toISOString(),
          searchTo: to.toISOString(),
        }
      }

      console.error(`date delta not implemented ${dateRange.delta}`)
    },
  },

  diary: {
    page: ({ pageNo = 1, pageSize = 20 }) => {
      return { pageNumber: pageNo, pageSize: pageSize }
    },

    query: ({ query }) => {
      return { whatText: query || '' }
    },

    categories: ({ categories }) => {
      return { serviceCategories: categories }
    },

    locationId: ({ location }) => {
      if (location.type !== 'locality') console.warn('Location is not locality type', location)
      return { locationId: [location.id] }
    },

    radius: ({ radius }) => {
      return { radiusKM: radius}
    },

    dateRange: ({ dateRange }) => {
      if (dateRange.delta < 0) {
        const from = dateRange.start || new Date()
        const to = dateRange.end ? addDays(dateRange.end, 1) : addDays(from, 90)

        return {
          whenIndex: 'Custom',
          searchFrom: from.toISOString(),
          searchTo: to.toISOString(),
        }
      }

      console.error(`date delta not implemented ${dateRange.delta}`)
    },

    diaryFormat: ({ diaryFormat }) => {
      // const df = diaryFormat.length > 0 ? diaryFormat : [-1]
      return { diaryFormatId: diaryFormat }
    },
  },
}

export const buildQuery = ({
  id,
  query,
  params,
}) => {
  const bt = query.builder || 'default'
  // const builder = builders[bt]
  const builder = builders[bt]

  if (!builder) {
    console.error(`Builder not found for ${bt}`)
    return
  }

  const qb = query.parameters.reduce((queryBody, { param, ...pargs }) => {
    if (!(param in builder)) {
      console.warn(`No query builder for param "${param}"`)
      return queryBody
    }

    const pr = builder[param]({ queryBody, ...params, ...pargs })
    return { ...queryBody, ...pr }
  }, {})

  // console.log({ queryBody })
  return qb
}

import React from 'react'

import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Divider,
} from '@material-ui/core'

import { useAnalytics } from 'use-analytics'

import { forwardRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  list: {
    minWidth: 360,
  },
  fullList: {
    width: 'auto',
  },
  navLink: {
    color: theme.palette.text.primary,
  },
  listIcon: {
    fontSize: 18,
  },
  listText: {
    fontSize: 18,
  },
}))

// TODO: Properly map functions

// eslint-disable-next-line react/display-name
const Reset = forwardRef(({
  children,
  ...itemProps
}, ref) => {

  return (
    <div
      ref={ref}
      {...itemProps}
    >
      {children}
    </div>
  )
})

const MainMenu = observer(withRouter(({
  history,
  match,
  items,
  onToggleMenu = () => undefined,
}) => {
  const store = useStore()
  const classes = useStyles()
  const { track } = useAnalytics()

  const handleReset = () => {
    store.resetAll()
    if (match !== '/') {
      history.push('/')
    }
  }

  const handleTrackMenuItem = (item) => () => {
    track('mainMenuItemClick', {
      category: 'Navigation',
      label: item.label,
    })
  }

  return (
    <div
      className={clsx(classes.list, classes.fullList)}
      onClick={onToggleMenu(false)}
      onKeyDown={onToggleMenu(false)}
    >
      {/* {items.map((item, i) => (<MenuItem key={i} {...item} />))} */}

      <List
      >
        {items.map((item, i) => {
          const listItemProps =
            item.route ? {
              component: NavLink,
              to: item.route,
            }
            : item.function === 'reset' ? {
              component: Reset,
              onClick: handleReset,
            }
            : null

          return (
            <ListItem
              key={i}
              button
              // component={listComponent}
              className={classes.navLink}
              // to={item.route}
              // {...item}
              onClick={handleTrackMenuItem(item)}
              {...listItemProps}
            >
              <ListItemIcon
                classes={{ root: classes.listIcon }}
              >
                <i className={clsx(item.icon, 'fa-fw')} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listText }}
                primary={item.label}
              />
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}))

export default MainMenu

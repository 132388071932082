import { emergency } from './emergency'
import { selfHelp } from './self-help'
import { survey } from './survey'
import { assets } from './assets'
import { mapStyle } from './map'
import { serviceChangeFields } from '../shared/search'

const config = {
  id: 'default',
  fonts: {
    google: {
      families: ['Nunito:300,400,600,700'],
    },
  },
  options: {
    title: 'Gladstone Mindcare',
    categoryMode: 'single',
    combineMode: 'categories',
  },
  analytics: {
    app: 'mindcare',
    plugins: [
      {
        provider: 'google-analytics',
        // trackingId: 'UA-188798033-1', // AMS Test
        trackingId: 'UA-147876591-5',
        // customScriptSrc: 'https://www.google-analytics.com/analytics_debug.js',
        siteSpeedSampleRate: 100,
      },
      {
        provider: 'cie-elastic',
        index: 'prod-ams-mindcare-analytics',
        // debug: true,
      },
    ],
  },
  appBar: {
    items: [
      {
        type: 'menuButton',
      },
      {
        type: 'title',
      },
    ],
  },
  meta: {
    title: 'Gladstone Mindcare Community Directory',
    description: 'Gladstone Mindcare promotes improved mental health and wellbeing by empowering our community through education, information and connectivity to achieve our Vision of a community that is mentally well, resilient and stigma free.',
  },
  routes: [
    {
      path: '/',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'home',
      },
    },
    {
      path: '/search',
      component: 'Search',
      search: true,
      meta: {
        title: 'Gladstone Mindcare Community Directory - Search for a Service',
      },
    },
    {
      path: '/survey',
      component: 'Survey',
      container: 'ContentPage',
      meta: {
        title: 'Gladstone Mindcare Community Directory - Guided Support Search',
      },
    },
    {
      path: '/emergency',
      component: 'EmergencyBlock',
      container: 'ContentPage',
      props: {
        data: emergency,
      },
      meta: {
        title: 'Gladstone Mindcare Community Directory - Emergency Help',
      },
    },
    {
      path: '/self-help-resources',
      component: 'SelfHelpBlock',
      container: 'ContentPage',
      props: {
        data: selfHelp,
      },
      meta: {
        title: 'Gladstone Mindcare Community Directory - Self Help Resources',
      },
    },
    {
      path: '/support',
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        title: 'Gladstone Mindcare Community Directory - Site Support',
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        title: 'Gladstone Mindcare Community Directory - 404 Not Found',
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],
  menu: [
    {
      id: 'home',
      label: 'Home',
      icon: 'fas fa-home-alt',
      route: '/',
    },
    {
      id: 'emergency',
      label: 'Emergency',
      icon: 'fas fa-plus-square',
      route: '/emergency',
      footer: true,
    },
    {
      id: 'self',
      label: 'Guided Support Search',
      icon: 'fas fa-clipboard-list-check',
      route: '/survey?pathway=self',
      footer: true,
    },
    {
      id: 'search',
      label: 'Search for a Service',
      icon: 'fas fa-search',
      route: '/search',
      footer: true,
    },
    {
      id: 'self-help-resources',
      label: 'Self Help Resources',
      icon: 'fas fa-user-check',
      route: '/self-help-resources',
      footer: true,
    },
    {
      id: 'support',
      label: 'Site Support',
      icon: 'fas fa-question',
      route: '/support',
      footer: true,
    },
    {
      id: 'reset',
      label: 'Clear Settings & Restart',
      icon: 'fas fa-undo',
      function: 'reset',
      footer: false,
    },
  ],
  footer: {
    columns: ['cie', 'menu', 'contact'],
  },
  homeButtons: {
    props: {
      xs: 12,
      md: 6,
    },
    buttons: [
      {
        id: 'emergency',
        label: 'Emergency',
        icon: 'fas fa-plus-square',
        route: '/emergency',
      },
      {
        id: 'survey-self',
        label: 'Guided Support Search',
        icon: 'fas fa-clipboard-list-check',
        route: '/survey?pathway=self',
      },
      {
        id: 'search',
        label: 'Search for a Service',
        icon: 'fas fa-search',
        route: '/search',
      },
      {
        id: 'self-help-resources',
        label: 'Self Help Resources',
        icon: 'fas fa-user-check',
        route: '/self-help-resources',
      },
    ],
  },
  contact: [
    {
      id: 'web',
      label: 'gladstonemindcare.org.au',
      icon: 'far fa-globe',
      href: 'https://gladstonemindcare.org.au',
      footer: true,
    },
    {
      id: 'email',
      label: 'info@gladstonemindcare.org.au',
      icon: 'fas fa-envelope',
      href: 'mailto:info@gladstonemindcare.org.au',
      footer: true,
    },
    {
      id: 'address',
      label: '147 Goondoon Street\nGladstone Qld 4680',
      icon: 'fas fa-map-marker-alt',
      href: 'https://goo.gl/maps/YAuWmZ1FC488TMDz8',
      footer: true,
    },
  ],
  categories: [
    {
      id: 'default',
      title: 'Information and Services',
      search: true,
      show: 'never',
      categories: [],
    },
    {
      id: 'k-early',
      title: 'At Risk/Early Intervention',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [80, 83, 93, 120] },
              { tags: [1305] },
            ],
            not: [
              { tags: [1306] },
            ],
          },
        },
      },
    },
    {
      id: 'k-mild',
      title: 'Mild',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [80, 83, 93, 95] },
              { tags: [1307] },
            ],
            not: [
              { tags: [1308] },
            ],
          },
        },
      },
    },
    {
      id: 'k-moderate',
      title: 'Moderate',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [83, 93, 95] },
              { tags: [1309] },
            ],
            not: [
              { tags: [1310] },
            ],
          },
        },
      },
    },
    {
      id: 'k-severe',
      title: 'Severe',
      search: true,
      show: 'preferred',
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [83, 93, 95] },
              { tags: [1311] },
            ],
            not: [
              { tags: [1312] },
            ],
          },
        },
      },
    },
    {
      id: 'emergency-services',
      title: 'Emergency Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [9, 147, 140, 126] },
              { tags: [1313] },
            ],
            not: [
              { tags: [1314] },
            ],
          },
        },
      },
    },
    {
      id: 'general-practitioners',
      title: 'General Practitioners',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [141] },
              { tags: [1315] },
            ],
            not: [
              { tags: [1316] },
            ],
          },
        },
      },
    },
    {
      id: 'mental-health-practitioners',
      title: 'Mental Health Practitioners',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [83, 93, 95] },
              { tags: [1317] },
            ],
            not: [
              { tags: [1318] },
            ],
          },
        },
      },
    },
    {
      id: 'child-and-youth-mental-health',
      title: 'Child and Youth Mental Health',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [83, 120, 124] },
              { tags: [1319] },
            ],
            not: [
              { tags: [1320] },
            ],
          },
        },
      },
    },
    {
      id: 'inpatient-mental-health-services',
      title: 'Inpatient Mental Health Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [84, 140] },
              { tags: [1321] },
            ],
            not: [
              { tags: [1322] },
            ],
          },
        },
      },
    },
    {
      id: 'mental-health-peer-support',
      title: 'Mental Health Peer Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [83] },
              { tags: [1323] },
            ],
            not: [
              { tags: [1324] },
            ],
          },
        },
      },
    },
    {
      id: 'suicide-bereavement-support',
      title: 'Suicide Bereavement Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [95] },
              { tags: [1325] },
            ],
            not: [
              { tags: [1326] },
            ],
          },
        },
      },
    },
    {
      id: 'gambling',
      title: 'Financial & Gambling Support',
      search: true,
      categories: [92],
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [92] },
              { tags: [1327] },
            ],
            not: [
              { tags: [1328] },
            ],
          },
        },
      },
    },
    {
      id: 'alcohol-and-drug-support',
      title: 'Alcohol and Drug Support',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [79] },
              { tags: [1329] },
            ],
            not: [
              { tags: [1330] },
            ],
          },
        },
      },
    },
    {
      id: 'social-support-services',
      title: 'Social Support Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [131, 93] },
              { tags: [1331] },
            ],
            not: [
              { tags: [1332] },
            ],
          },
        },
      },
    },
    {
      id: 'atsi',
      title: 'Aboriginal & Torres Strait Is. Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: [86, 87, 88] },
              { tags: [1333] },
            ],
            not: [
              { tags: [1334] },
            ],
          },
        },
      },
    },
    {
      id: 'lgbtqi',
      title: 'LGBTQI+ Services',
      search: true,
      searchOptions: {
        filter: {
          bool: {
            or: [
              { categories: 139 },
              { tags: [1335] },
            ],
            not: [
              { tags: [1336] },
            ],
          },
        },
      },
    },
  ],
  contentRegions: [
    {
      id: 'fortitude-valley',
      title: 'Fortitude Valley',
      regions: [4883],
    },
  ],
  map: {
    style: mapStyle,
  },
  search: {
    ui: {
      homeButton: {
        show: true,
        path: '/',
      },
    },
    location: {
      showLocationDialog: true,
      initLocation: 'dialog',
      defaultLocation: {
        type: 'locality',
        id: 5187,
        default: true,
        label: "Gladstone 4680",
        councilId: 28,
        postcode: "4680",
        lat: -23.84159,
        lng: 151.24977,
      },
      defaultLocationLabel: "Gladstone",
    },
    survey: {
      autoSelectSingleCategory: true,
    },
    queries: [
      {
        id: 'services',
        builder: 'default',
        parameters: [
          { param: 'page' },
          { param: 'sort', sortField: 'Distance', sortDirection: 'ASC' },
          { param: 'query' },
          // { param: 'categories' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'age' },
          { param: 'gender' },
          { param: 'filters' },
        ],
      },
    ],
    searches: {
      services: {
        id: 'services',
        type: 'directory',
        showSiteSupport: true,
        resultsHeader: {
          type: 'markdown',
          prefix: 'results',
        },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
    results: {
      service: {
        components: [
          {
            component: 'Header',
            options: {
              type: 'logo',
              showDistance: true,
              showSeparator: true,
            },
          },
          {
            component: 'Title',
          },
          {
            component: 'Details',
            fields: [
              { key: 'operatingDays', icon: 'far fa-clock' },
              { key: 'address', icon: 'fas fa-map-marker-alt' },
              { key: 'phone', icon: 'fas fa-phone' },
            ],
          },
          {
            component: 'Description',
            hideVariant: 'map',
          },
          {
            component: 'ServiceChanges',
            fields: serviceChangeFields,
          },
        ],
      },
    },
    tabs: [
      {
        id: 'services',
        title: 'Services',
        icon: 'fas fa-building',
        component: 'SearchResults',
        map: true,
        options: {
          tabHint: true,
          search: 'services',
        },
      },
      {
        id: 'favourites',
        title: 'Favourites',
        icon: 'fas fa-heart',
        component: 'SearchResults',
        map: true,
        options: {
          search: 'favourites',
        },
      },
    ],

    parameters: {
      query: {
        id: 'query',
        type: 'query',
        label: 'Search',
        icon: 'far fa-search',
      },
      location: {
        id: 'location',
        type: 'location',
        label: 'Location',
        icon: 'fas fa-map-marker-question',
      },
      radius: {
        id: 'radius',
        type: 'radius',
        label: 'Radius',
        icon: 'far fa-running',
      },
      categories: {
        id: 'categories',
        type: 'categoryButtons',
        label: 'Categories',
        icon: 'far fa-stream',
      },
      age: {
        id: 'age',
        type: 'select',
        label: 'Age',
        options: ['0 - 15', '16 - 25', '26 - 40', '41 - 64', '65+'],
      },
      gender: {
        id: 'gender',
        type: 'select',
        label: 'Gender',
        options: ['Male', 'Female', 'Intersex', 'Transgender'],
      },
    },

    parameterGroups: {
      desktop: {
        controls: [
          { param: 'query' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'categories' },
          { param: 'age' },
          { param: 'gender' },
        ],
      },
      mobileSearch: {
        controls: [
          { param: 'query' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'age' },
          { param: 'gender' },
        ],
      },
      mobileCategories: {
        controls: [
          { param: 'categories' },
        ],
      },
    },
  },
  assets,
  survey,
}

export default config

import React from 'react'
import { configure, toJS } from 'mobx'

import { RootStore } from './models/RootStore'
import { initStoreConfigs } from './utils/user-data'

// proxies not compatible with ie11

configure({
  useProxies: 'never',
})

const createStore = ({ defaultConfig, userConfig }) => {
  try {
    return RootStore.create(userConfig || defaultConfig)
  } catch (err) {
    console.error('Error loading store, reverting to default config', err)
    return RootStore.create(defaultConfig)
  }
}

const store = (() => {
  const cfg = initStoreConfigs()
  const s = createStore(cfg)
  s.postInit()
  return s
})()

window.store = store
window.toJS = toJS

const storeContext = React.createContext(store)
export const useStore = () => React.useContext(storeContext)

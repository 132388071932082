import logoSmall from './assets/images/simple_logo@0.75x.png'
import logo from './assets/images/full_logo@0.5x.png'
import mapMarker from './assets/images/marker-mg.png'
import cieLogo from './assets/images/cie-logo.png'

export const assets = {
  logo,
  logoSmall,
  mapMarker,
  cieLogo,
}

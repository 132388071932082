import React from 'react'

import clsx from 'clsx'
// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // '& * > *': {
    //   margin: theme.spacing(2, 1),
    // }
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(0.5),
  },
  clearButton: {
    // marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  clearIcon: {
    marginRight: theme.spacing(0.5),
  },
}))

const CategoryButton = withStyles(() => ({
  root: {
    justifyContent: 'flex-start',
  },
  label: {
    textTransform: 'none',
    fontWeight: 400,
  },
}))(Button)

const CategoryButtons = observer(({
  selectMode = 'single',
  categories = [],
  selected = selectMode === 'single' ? null : [],
  onChange = () => undefined,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const onClear = () => {
    track('categoriesClearSelected', {
      category: 'Categories',
      label: 'Clear',
    })

    onChange(selectMode === 'single' ? null : [])
  }

  const handleSelectCategory = id => () => {

    track('categoriesCategoryClick', {
      category: 'Categories',
      label: id,
    })

    if (selectMode === 'single') {
      const sc = id === selected ? null : id
      onChange(sc)
    }

    if (selectMode === 'multiple') {
      const oldSel = selected || []
      const newSel = oldSel.includes(id)
        ? oldSel.filter(c => c !== id)
        : [...oldSel, id]

      onChange(newSel)
    }
  }

  const isSelected = id => {
    if (selectMode === 'single') {
      return id === selected
    }

    if (selectMode === 'multiple') {
      if (!selected) return false

      return selected.includes(id)
    }
  }

  const isClearEnabled = selectMode === 'single'
    ? !!selected
    : selected && selected.length > 0

  return (
    <div className={classes.root}>
      {/* <p>{selectMode}</p>
      <p>{JSON.stringify(selected, null, 0)}</p> */}
      {categories.map((c, i) => (
        <CategoryButton
          key={i}
          className={classes.button}
          disableElevation
          // size="small"
          variant="contained"
          color={isSelected(c.id) ? 'primary' : 'default'}
          fullWidth
          onClick={handleSelectCategory(c.id)}
        >
          {c.title}
        </CategoryButton>
      ))}

      {isClearEnabled &&
        <Button
          className={classes.clearButton}
          onClick={onClear}
        >
          <i className={clsx('far fa-times fa-fw', classes.clearIcon)} />Clear
        </Button>
      }

    </div>
  )
})

export default CategoryButtons

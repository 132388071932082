import React, { useState } from 'react'

import * as Yup from 'yup'
import clone from 'lodash/clone'
import { useAnalytics } from 'use-analytics'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import { submitSupportTicket } from 'data/api/cie-api'

import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    // backgroundColor: '#f0f',
  },
  control: {
    // backgroundColor: '#f0f',
    width: '100%',
    margin: theme.spacing(1, 0),
    '& label': {
      transform: 'translate(0, 0px) scale(1.0)',
      fontSize: 15,
    },
  },
}))

const BootstrapTextField = withStyles((theme) => ({
  root: {
    // '& label': {
    //   marginTop: theme.spacing(3),
    // },
    'label + &': {
      marginTop: theme.spacing(3),

    },
    width: '100%',
    margin: theme.spacing(3, 0, 2),
    '& label': {
      transform: 'translate(4px, -1.4rem)',
    },
    '& p.Mui-error': {
      marginLeft: 4,
    },
    input: {
      borderRadius: 4,
      border: '1px solid #ced4da',
      padding: '10px 12px',

    },
    // '& input:valid + fieldset': {
    //   borderColor: 'green',
    //   borderWidth: 2,
    // },
    // '& input:invalid + fieldset': {
    //   borderColor: 'red',
    //   borderWidth: 2,
    // },
    // '& input:valid:focus + fieldset': {
    //   borderLeftWidth: 6,
    //   padding: '4px !important', // override inline-style
    // },
  },
}))(TextField)

// Formik

const SupportFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Name is too long')
    ,
  email: Yup.string()
    .email('Invalid email address')
    .max(100, 'Email is too long')
    .required('An email address is required')
    ,
  subject: Yup.string()
    .max(100, 'Subject is too long')
    ,
  description: Yup.string()
    .max(1024, 'Description is too long')
    .required('A description is required')
    ,
})

const initialValues = {
  name: '',
  email: '',
  subject: '',
  description: '',
}

// Alert

const SubmitConfirmation = ({ open, onClose, title, message }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="submit-confirmation-title"
      aria-describedby="submit-confirmation-description"
    >
      <DialogTitle id="submit-confirmation-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="submit-confirmation-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

const SupportForm = () => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const { tenantConfig } = useConfig()

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmation, setConfirmation] = useState(null)

  const site =
    tenantConfig.options.freshdeskTitle ||
    tenantConfig.options.title ||
    'Untitled AMS Site'

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    track('supportFormSubmit', {
      category: 'Support',
      label: 'Submit',
    })

    setSubmitting(true)

    const ticket = {
      Site: site,
      FromName: values.name,
      FromEmail: values.email,
      Subject: values.subject,
      Message: values.description,
    }

    const res = await submitSupportTicket(ticket)

    setSubmitting(false)

    if (res) {
      setConfirmation({
        title: 'Support Ticket Submitted',
        message: 'Thank you for submitting a support ticket. You should recieve a confirmation via email and our support staff will get in touch.',
      })
      setShowConfirmation(true)
      resetForm(clone(initialValues))
    } else {
      track('supportFormSubmitError', {
        category: 'Support',
        label: 'Error',
      })

      setConfirmation({
        title: 'Error Submitting Ticket',
        message: 'An error occurred submitting a support ticket. Please try again later.',
      })
      setShowConfirmation(true)
    }
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
  }

  return (
    <>

      <SubmitConfirmation
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        title={confirmation?.title}
        message={confirmation?.message}
      />

      <Formik
        initialValues={clone(initialValues)}
        validationSchema={SupportFormSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, resetForm, isValid }) => (
          <Form className={classes.root}>
            <Field
              component={BootstrapTextField}
              name="name"
              type="string"
              label="Your name"
              variant="outlined"
              InputLabelProps={{
                shrink: false,
              }}
            />
            <Field
              component={BootstrapTextField}
              name="email"
              type="email"
              label="Your email *"
              variant="outlined"
              InputLabelProps={{
                shrink: false,
              }}
            />
            <Field
              component={BootstrapTextField}
              name="subject"
              type="subject"
              label="Subject"
              variant="outlined"
              InputLabelProps={{
                shrink: false,
              }}
            />

            <Field
              component={BootstrapTextField}
              name="description"
              type="string"
              label="Description *"
              variant="outlined"
              InputProps={{
                multiline: true,
                rows: 6,
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <Box mr={1}>
                <Button
                  variant="contained"
                  // color
                  disableElevation
                  disabled={isSubmitting}
                  onClick={() => resetForm(clone(initialValues))}
                >
                  Reset
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SupportForm

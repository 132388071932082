import { types, flow } from 'mobx-state-tree'

import { toJS } from 'mobx'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import flatMap from 'lodash/flatMap'
import uniqBy from 'lodash/uniqBy'

import { useConfig } from 'config/config'

import { Content } from './Content'
import { getCommissionedServicesByOutlet } from 'data/api/commissioned-services'

const { tenantConfig } = useConfig()

export const TenantOptions = types
  .model({
    title: '',
    categoryMode: types.enumeration('CategoryMode', ['single', 'multiple']),
    combineMode: types.enumeration('DefaultCategories', ['categories', 'tags', 'commissioned-services', 'filters']),
    informationCategories: types.optional(types.boolean, false),
  })

export const MetaCategory = types
  .model({
    id: '',
    title: '',
    url: '',
    icon: '',
    search: true,
    show: '',
    categories: types.maybeNull(types.array(types.integer), []),
    tags: types.maybeNull(types.array(types.integer), []),
    commissionedServices: types.maybeNull(types.array(types.integer), []),
    diaryFormat: types.maybeNull(types.array(types.integer), []),
  })

  .volatile(self => ({
    searchOptions: null,
    // badge: null,
  }))

  .actions(self => ({
    setVolatile(category) {
      self.searchOptions = category.searchOptions
      // self.badge = category.badge
    },
  }))

export const Tenant = types
  .model({
    options: types.optional(TenantOptions, {
      categoryMode: 'single',
      combineMode: 'categories',
      informationCategories: false,
    }),
    categories: types.array(MetaCategory, []),
    content: types.optional(Content, {}),
  })

  .views(self => ({
    get metaCategoryMap() {
      return keyBy(self.categories, 'id')
    },

    getCategory(id) {
      return id in self.metaCategoryMap ? self.metaCategoryMap[id] : undefined
    },

    // lookup categories for a csid

    get commissionedServiceCategoryMap() {
      const cscats = self.categories.reduce((acc, curr) => {
        (curr?.commissionedServices || []).forEach(csid => {
          // console.log('csid', csid)
          acc[csid] = [...(acc[csid] || []), curr.id]
        })
        return acc
      }, {})

      const res = mapValues(cscats, c => uniq(c.sort((a, b) => a.localeCompare(b))))
      return res
    },

    combinedCategories(selected) {
      const sel = toJS(selected)

      const cats = !sel ? self.categories.map(x => x.id)
        : Array.isArray(sel) ? selected
        : typeof selected === 'string' ? [selected]
        : []

      const sc  = uniq(flatten(cats
        .map(c => toJS(self.metaCategoryMap[c].categories))
        .filter(x => x)
      ))

      return sc
    },

    categoriesFromParams(params) {
      const cmode = tenantConfig.options.categoryMode

      const rconfig = tenantConfig?.search?.router?.category
      if (!rconfig) throw new Error('No config')

      const field = rconfig.field || 'title'
      const delimiter = rconfig.delimiter || '+'

      if (cmode === 'single') {
        // console.log('single')
        if (!params.category) return null

        const pp = params.category.replace('_', ' ')
        const pc = self.categories.find(c => c[field] === pp)

        return pc ? pc.id : null
      }

      if (cmode === 'multiple') {
        // console.log('multiple')
        if (!params.category) return []

        const pp = params.category.split(delimiter).map(c => c.replace('_', ' '))
        const cm = keyBy(self.categories, field)
        const cids = pp.filter(c => c in cm).map(c => cm[c].id)

        return cids
      }

      return null
    },

    combinedTags(selected) {
      if (!selected) return null

      if (typeof selected === 'string') {
        const tags = self.metaCategoryMap[selected].tags || []
        return uniq(tags).join('|')
      }

      return null
    },

    combinedDiaryFormat(selected) {
      // use this pattern for categories and tags!!
      const cats = !selected ? self.categories.map(x => x.id)
        : Array.isArray(selected) ? selected
        : typeof selected === 'string' ? [selected]
        : []

      const df = uniq(flatten(cats
        .map(c => toJS(self.metaCategoryMap[c].diaryFormat))
        .filter(x => x)
      ))

      return df
    },

    combinedCommissionedServices(selected) {
      // use this pattern for categories and tags!!
      const cats = !selected ? self.categories.map(x => x.id)
        : Array.isArray(selected) ? selected
        : typeof selected === 'string' ? [selected]
        : []

      const cs = uniq(flatten(cats
        .map(c => toJS(self.metaCategoryMap[c].commissionedServices))
        .filter(x => x)
      ))

      return cs
    },

    categoriesForCommissionedService(id) {
      const cats = (self.commissionedServiceCategoryMap[id] || []).map(id => self.getCategory(id))
      return cats
    },

    getContent({
      type = 'static',
      region = 'default',
      id = 'default',
    }) {
      const ck = (type, region, id) => `${type}/${region}/${id}`

      const regions = region === 'default' ? [region] : [region, 'default']

      const content = regions
        .map(r => self.content.items.get(ck(type, r, id)))
        .find(c => c)

      return content
    },
  }))

  .actions(self => ({
    loadVolatile() {
      (tenantConfig.categories || []).forEach(cat => self.metaCategoryMap[cat.id].setVolatile(cat))
    },

    // this might be redundant now!
    loadCommissionedServices: flow(function* loadCommissionedServices({
      commissioningOutletId,
      typeMap,
    }) {

      if (!commissioningOutletId) {
        console.error(`Invalid commissioningOutletId`)
        return
      }

      const cso = yield getCommissionedServicesByOutlet(commissioningOutletId)

      if (!cso || cso.length === 0) {
        console.error(`No valid commissioned services returned for outlet ${commissioningOutletId}`)
        return
      }

      // bin cs types by category
      // a commisioned service can be in more than one category (cs type)

      const tagMap = mapValues(keyBy(typeMap, 'tagId'), v => v.category)

      const csm = cso.reduce((acc, curr) => {
        if (!curr.types) return acc

        curr.types.forEach(t => {
          const catId = tagMap[t.tagId]
          if (!catId) return

          acc[catId] = [...(acc[catId] || []), curr.commissionedServiceId]
        })

        return acc
      }, {})

      // get icons

      const icons = uniqBy(flatMap(cso.map(cs => cs.types)), 'tagId')
      .map(t => ({ id: tagMap[t.tagId], icon: t.icon }))

      // save to ams categories

      self.categories.forEach(cat => {
        cat.icon = icons[cat.id] || ''
        cat.commissionedServices = csm[cat.id] || []
      })
    }),
  }))

import React from 'react'

import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'

import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles'
import {
  Tabs,
  Tab,
  Button,
  useMediaQuery,
} from '@material-ui/core'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  hidden: {
    display: 'none !important',
  },
  toolbarButton: {
    // margin: theme.spacing(2, 0),
    fontSize: 20,
  },
  tabs: {
  },
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > i': {
    },
  },
}), { name: 'AMSDesktopSearchToolbar' })

const DesktopSearchToolbar = observer((({
  showSearch,
  onClickSearch = () => undefined,
  tabs,
}) => {
  const classes = useStyles()
  const { tenantConfig } = useConfig()
  const { track } = useAnalytics()
  const history = useHistory()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const store = useStore()
  const tab = store.search.ui.selectedTab

  const homeButton = tenantConfig.search?.ui?.homeButton

  const handleClickHome = () => {
    track('searchToolbarButtonClick', {
      category: 'Navigation',
      label: 'Home',
    })

    history.push('/')
  }

  const handleClickToggleSearch = () => {
    track('searchToolbarButtonClick', {
      category: 'Search',
      label: 'Toggle Search Params',
    })

    onClickSearch(!showSearch)
  }

  const handleTabChange = (event, value) => {
    const tabc = tabs[value]

    track('searchToolbarSelectTab', {
      category: 'Search',
      label: tabc.title,
    })

    store.search.ui.setSelectedTab(value)
  }

  return (
    <div className={classes.root}>
      {isTablet && (
        <Button
          className={classes.toolbarButton}
          color="primary"
          onClick={handleClickToggleSearch}
        >
          <i className="fas fa-search fa-fw" />
        </Button>
      )}

      {homeButton?.show && (
        <Button
          className={classes.toolbarButton}
          color="primary"
          href={homeButton.path}
          // onClick={handleClickHome}
        >
          <i className="fas fa-home-alt fa-fw" />
        </Button>
      )}

      <Tabs
        className={classes.tabs}
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        // centered
        >
        {tabs.map((t, i) => (
          <Tab
            key={i}
            label={
              <div className={classes.tabLabel}>
                <i className={t.icon} />&nbsp;&nbsp;{t.title}
              </div>
            }
          />
        ))}
      </Tabs>
    </div>
  )
}))

export default DesktopSearchToolbar

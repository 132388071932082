import React from 'react'

import { useQuery } from 'react-query'

import { getGeographySelectOptions } from 'data/api/geography'

import {
  Select,
  MenuItem,
} from '@material-ui/core'

// eslint-disable-next-line react/display-name
const GeographySelect = ({
  value = null,
  paramConfig = null,
  onChange = () => undefined,
  ...inputProps
}) => {
  const { loading, data: options } = useQuery(
    paramConfig.queryKey,
    async () => await getGeographySelectOptions(paramConfig),
    { cacheTime: 'Infinity', refetchOnWindowFocus: false }
  )

  const selected = (options && value && value.type === 'geography')
    ? options.findIndex(o => o.id === value.id)
    : ''

  const handleChange = event => {
    const val = options[event.target.value]
    onChange(val)
  }

  return (
    <Select
      {...inputProps}
      value={selected}
      variant="outlined"
      disabled={loading}
      onChange={handleChange}
    >
      {options && options.map((o, i) => (
        <MenuItem key={i} value={i}>{o.label}</MenuItem>
      ))}
    </Select>
  )
}

export default GeographySelect

import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { get } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { useMergeOptions } from 'ui/hooks/useMergeOptions'

import {
  Box,
  AppBar,
  Toolbar,
  // Link,
  Typography,
  Button,
  IconButton,
 } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { useConfig } from 'config/config'

// const { tenantConfig } = useConfig()

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary,
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 48,
    },
  },
  menuButton: {
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  logo: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    margin: theme.spacing(0, 1),
    '& > img': {
      height: 32,
    },
  },
  parametersContainer: {
    flex: '1 0 0',
  },
  parametersPortal: {
    width: '100%',
  },
  alignRight: {
    marginLeft: 'auto',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.contrastText,
  },
})

const MenuButton = ({
  classes,
  onMenuClick = () => undefined,
}) =>
  <IconButton
    edge="start"
    className={classes.menuButton}
    color="inherit"
    aria-label="menu"
    onClick={onMenuClick}
  >
    <MenuIcon />
  </IconButton>

const Logo = ({
  classes,
  image = 'logo',
  ...containerProps
}) => {
  const { tenantConfig } = useConfig()

  const img = tenantConfig.assets[image]
  if (!img) return null

  const Container =
    containerProps.href ? 'a' :
    containerProps.to ? RouterLink :
    'div'

  return (
    <Container
      className={classes.logo}
      {...containerProps}
    >
      <img
        src={img}
        alt="Logo"
      />
    </Container>
  )
}

const AppBarButton = ({
  classes,
  label,
  icon,
  ...buttonProps
}) => {

  const bprops = {
    underline: 'none',
    ...buttonProps,
  }

  return (
    <div className={classes.alignRight}>
      <Button
        className={classes.button}
        {...bprops}
      >
        <Box mr={1}><i className={icon} /></Box>
        <span>{label}</span>
      </Button>
    </div>
  )
}

const Title = ({ classes, title }) => {
  const { tenantConfig } = useConfig()
  return (
  <Typography variant="h6" className={classes.title}>
    {title || tenantConfig.options.title}
  </Typography>
  )
}

const Parameters = ({ classes, portalId = 'search-params-app-bar' }) =>
  <div className={classes.parametersContainer}>
    <div id={portalId} className={classes.parametersPortal} />
  </div>

const componentMap = {
  menuButton: MenuButton,
  logo: Logo,
  parameters: Parameters,
  title: Title,
  button: AppBarButton,
}

const MainAppBar = ({
  classes,
  // options = {},
  childRef: ref,
  onMenuClick = () => undefined,
}) => {
  const { tenantConfig } = useConfig()

  const toolbarItems = (tenantConfig.appBar?.items || []).map((item, i) => {
    if (!(item.type in componentMap)) return null

    const itemProps = {
      classes,
      ...item,
      ...(item.type === 'menuButton' && ({ onMenuClick})),
    }

    const Item = componentMap[item.type]
    return Item && <Item key={i} {...itemProps} />
  })

  return (
    <AppBar
      position="static"
      elevation={0}
      ref={ref}
      className={classes.root}
      color="secondary"
    >
      <Toolbar
        className={classes.toolBar}
      >
        {toolbarItems}
      </Toolbar>
    </AppBar>
  )
}

const defaultOptions = {
  items: [],
}

const StyledAppBar = withStyles(styles, { name: 'AMSAppBar' })(MainAppBar)

const MainAppBarContainer = ({ options, ...appBarProps }) => {
  const opts = useMergeOptions(defaultOptions, options)
  return <StyledAppBar options={opts} {...appBarProps} />
}

export default MainAppBarContainer

import _ from 'lodash'

const builders = {
  directory: {
    nodeBuilder: 'elastic',
    nodeMap: {
      categories: {
        builder: 'terms',
        field: 'categories',
      },
      tags: {
        builder: 'terms',
        field: 'serviceTags',
      },
    },
  },
}

const nodeBuilders = {
  elastic: {
    bool: (self, node) => {
      const bkeys = {
        and: 'filter',
        or: 'should',
        not: 'must_not',
      }

      const diff = _.difference(_.keys(node.bool), _.keys(bkeys))
      if (diff.length > 0) throw `Invalid keys: ${diff.join(', ')}`

      const bfe = _.reduce(node.bool, (acc, v, k) => {
        if (!(k in bkeys)) return acc
        acc[bkeys[k]] = Array.isArray(v)
          ? v.map(n => self.buildNode(self, n))
          : self.buildNode(self, v)

        return acc
      }, {})

      return { bool: { ...bfe } }
    },

    terms: (self, node, { key, field }) => {
      if (node.terms) return node
      if (!key || !field) throw `Invalid node map`

      return { terms: { [field]: node[key] }}
    },
  },
}

const buildNode = (self, node) => {
  const keys = _.keys(node)
  if (keys.length !== 1) throw `Invalid keys: ${keys.join(', ')}`

  const nkey = keys[0]
  const nmap = self.nodeMap[nkey]
  const bldkey = nmap?.builder || nkey
  const bld = self.nodeBuilders[bldkey]

  if (!bld || typeof(bld) !== 'function') throw `Invalid builder: ${bldkey}`

  return bld(self, node, { key: nkey, ...nmap })
}

export const buildFilter = ({
  builder = 'directory',
  filter = {},
}) => {
  console.log('buildFilter')
  const bld = builders[builder]
  if (!bld) throw `Unknown builder: ${bld}`

  const nb = nodeBuilders[bld.nodeBuilder]
  if (!nb) throw `Unknown node builder: ${bld.nodeBuilder}`

  const self = {
    buildNode: buildNode,
    nodeBuilders: nb,
    nodeMap: bld.nodeMap,
  }

  const res = self.buildNode(self, filter)
  console.log('res', res)
  return res
}


import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import Webfont from 'webfontloader'
import * as serviceWorker from './serviceWorker';

import { AnalyticsProvider } from 'use-analytics'
import { analytics } from 'data/analytics/analytics'

import 'react-perfect-scrollbar/dist/css/styles.css'

// import '@fortawesome/fontawesome-pro/css/light.css'
// import '@fortawesome/fontawesome-pro/css/regular.css'

import App from './ui/main/App'

// import 'mobx-react-lite/batchingForReactDom'

// eslint-disable-next-line no-undef
const tenantConfig = require(`tenants/${process.env.TENANT}/config`).default

// eslint-disable-next-line no-undef
const tenantTheme = require(`tenants/${process.env.TENANT}/theme`).default

Webfont.load(tenantConfig.fonts)

const queryClient = new QueryClient()

ReactDOM.render(
  <AnalyticsProvider instance={analytics}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={tenantTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </AnalyticsProvider>,
  document.querySelector('#root'),
)

serviceWorker.unregister()

import React from 'react'
import { observer } from 'mobx-react-lite'

// import Typography from '@material-ui/core/Typography'
// import Divider from '@material-ui/core/Divider'

import SimpleMarkdown from '../atoms/SimpleMarkdown'

import { useStore } from 'data/store/store'

const StaticMarkdown = observer((props) => {
  const store = useStore()
  const content = store.search.getStaticContent(props.contentId)

  return (
    <div>
      <SimpleMarkdown>{content.body}</SimpleMarkdown>
    </div>
  )

})

export default StaticMarkdown

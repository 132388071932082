import React from 'react'

import pull from 'lodash/pull'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import {
  FormControl,
  FormLabel,
  Button,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // '& * > *': {
    //   margin: theme.spacing(2, 1),
    // }
  },
  group: {
    margin: theme.spacing(2, 0),
  },
  groupTitle: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(0.5),
  },
}))

const CategoryButton = withStyles(() => ({
  root: {
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 400,
  },
}))(Button)


const SurveyCategories = ({
  // name,
  question,
  label,
  metaCategoryMap = {},
  selected = [],
  onChange = () => undefined,
}) => {
  const classes = useStyles()

  const isSelected = id => {
    if (!selected) return false
    return selected.findIndex(i => i === id) > -1
  }

  const handleClick = id => {
    if (isSelected(id)) {
      onChange(pull(selected, id))
    } else {
      onChange([...selected, id])
    }
  }

  const CategoryGroup = ({ group }) => {
    return (
      <div className={classes.group}>
        <Typography className={classes.groupTitle} variant="h6">{group.title}</Typography>
        {/* <br /> */}

        {group.categories
          .filter(c => c in metaCategoryMap)
          .map((c, i) => (
          <CategoryButton
            key={i}
            className={classes.button}
            disableElevation
            variant="contained"
            color={isSelected(c) ? 'primary': 'default'}
            fullWidth
            onClick={() => handleClick(c)}
          >
            {metaCategoryMap[c].title}
          </CategoryButton>
        ))}
      </div>
    )
  }

  return (
    <FormControl className={classes.root}>
      <FormLabel>{label}</FormLabel>

      {question.categoryGroups.map((group, i) => (
        <CategoryGroup key={i} group={group} />
      ))}

    </FormControl>
  )
}

export default SurveyCategories

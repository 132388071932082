import React, { useState, useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

// returns the route config and match, location etc. for matching route

export const useRouteConfig = (routeConfig) => {
  const [routes] = useState(routeConfig)
  const location = useLocation()
  const [result, setResult] = useState({})

  useEffect(() => {
    if (!routes || routes.length === 0) {
      console.error('No routes specified.')
      return
    }

    // console.log('useRouteConfig', location)

    const rm = routes.map((r, index) => ({
      index,
      config: routes[index],
      location,
      match: matchPath(location.pathname, {
        path: r.path,
        exact: !!r.exact,
        strict: !!r.strict,
      }),
    }))

    const fm = rm.find(x => !!x.match)
    setResult(fm || {})
  }, [location, routes])

  return result
}

import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import mapValues from 'lodash/mapValues'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import map from 'lodash/map'

import Mustache from 'mustache'
import { Liquid } from 'liquidjs'

import { Helmet } from 'react-helmet'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useConfig } from 'config/config'
import { useRouteConfig } from 'ui/hooks/useRouteConfig'
import { useStore } from 'data/store/store'

const liquid = new Liquid()

const defaultTemplates = {
  robots: 'all',
  canonical: 'default',
  title: '{{ tenant.options.title }}',
  description: '{{ tenant.options.title }} Description.',
}

const regionsTitle = (params) => () => {
  const pc = mapValues(params, p => p.replaceAll('_', ' '))

  if (!pc.state) return `Browse States`
  if (!pc.council) return `Browse Councils in ${pc.state}`
  if (!pc.locality) return `Browse Suburbs in ${pc.council} Council, ${pc.state}`
  return `Browse Healthcare Services in ${pc.locality}, ${pc.state}`
}

const metaBuilders = {
  // default only, returns the current path
  canonical: ({ value, routeConfig }) => {
    if (!value) return null
    if (value !== 'default') return null

    const path = routeConfig?.location?.pathname
    if (!path) return null

    try {
      return path.split('?')[0]
    } catch {
      return null
    }
  },

  title: ({ value, routeConfig, view }) => {
    if (!value || typeof value !== 'string') return value
    return liquid.parseAndRenderSync(value, view)
  },

  description: ({ value, routeConfig, view }) => {
    if (!value || typeof value !== 'string') return value
    return liquid.parseAndRenderSync(value, view)
  },
}

const buildMeta = ({ meta, routeConfig, view }) => {
  return mapValues(meta, (value, key) => {
    if (!(key in metaBuilders)) return value
    return metaBuilders[key]({ value, routeConfig, view })
  })
}

const Headers = observer(() => {
  const { tenantConfig } = useConfig()
  const routeConfig = useRouteConfig(tenantConfig.routes)
  const store = useStore()
  const [meta, setMeta] = useState(null)

  const templateView = () => ({
    ...store,
    params: routeConfig?.match?.params,
    regionsTitle: regionsTitle(routeConfig?.match?.params),
  })

  useDeepCompareEffect(() => {
    if (!routeConfig.config) {
      return
    }

    const merged = [defaultTemplates, tenantConfig?.meta, routeConfig?.config?.meta]
      .filter(x => !!x)
      .reduce((acc, cur) => ({ ...acc, ...cur }), {})

    const built = buildMeta({ meta: merged, routeConfig, view: templateView()})
    // console.log({ meta })

    setMeta(built)

  }, [routeConfig, store.search.path])

  return (
    <Helmet>
      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}
      {meta?.robots && <meta name="robots" content={meta.robots} />}
      {meta?.title && <title>{meta.title}</title>}
      {meta?.description && <meta name="description" content={meta.description} />}
    </Helmet>
  )
})

export default Headers

import React, { useState } from 'react'

import useDeepCompareEffect from 'use-deep-compare-effect'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  Redirect,
  useRouteMatch,
} from 'react-router-dom'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { getLocalityByParams } from 'data/api/cie-api'

const SearchRouteHandler = observer(() => {

  const match = useRouteMatch()
  const store = useStore()
  const { tenantConfig } = useConfig()
  const rconfig = tenantConfig.search.router

  const location = toJS(store.search.params.location) || {}
  const selectedCategories = toJS(store.search.params.selectedCategories) || []

  const [parsedUrl, setParsedUrl] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState(null)

  useDeepCompareEffect(() => {
    onRouterParamsChange(match.params)
  }, [match.params])

  useDeepCompareEffect(() => {
    // block these updates (on mount) until url params are parsed
    if (!parsedUrl) return

    if (match.url !== store.search.path) {
      // window.history.pushState(null, null, store.search.path)
      setRedirectUrl(store.search.path)
    } else {
      setRedirectUrl(null)
    }
  }, [location, selectedCategories])

  const paramHandlers = {
    location: {
      prepare: async params => {
        // console.log('prepare location')
        if (store.search.params.isLocationParamsSelected(params)) return {
          change: false,
        }

        const newLocation = await getLocalityByParams(params)
        if (params.locality && !newLocation) throw new Error('Location not found')

        return {
          change: true,
          value: newLocation,
        }
      },
      update: async ({ change, value }) => {
        // console.log('update location')
        if (!change) return

        store.search.params.setLocation(value)
      },
    },
    category: {
      prepare: async params => {
        // console.log('prepare category')
        const cats = store.tenant.categoriesFromParams(params)
        return {
          change: true,
          value: cats,
        }
      },
      update: async ({ change, value }) => {
        // console.log('update category')
        if (!change) return
        store.search.params.setSelectedCategories(value)
      },
    },
  }

  const onRouterParamsChange = async (params) => {

    if (!rconfig) return
    // console.log('match', match, match.params)

    // redirect from default
    // TODO: url via options

    if (match.url === '/' && match.url !== store.search.path) {
      // console.log('redirect')
      // window.history.pushState(null, null, store.search.path)
      setRedirectUrl(store.search.path)
      return
    }

    // no change

    if (match.url === store.search.path) {
      // console.log('no change')
      // return
      setRedirectUrl(null)
    }

    // collect paramater handlers

    const ph = Object.keys(paramHandlers).filter(k => k in rconfig).map(k => paramHandlers[k])

    try {
      // run prepare params
      const prepare = await Promise.all(ph.map(h => h.prepare(params)))


      // update changed params
      await Promise.all(ph.map((h, i) => h.update(prepare[i])))

      // allow responses to store change
      setParsedUrl(true)
    } catch {
      // window.history.pushState(null, null, '/404')
      setRedirectUrl('/404')
    }
  }

  if (redirectUrl) return <Redirect push to={redirectUrl} />

  return null
})

export default SearchRouteHandler

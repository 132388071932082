import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormLabel,
  Slider,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  slider: {
    width: 'auto',
    margin: theme.spacing(3, 3),
  },
}))

const marks = [10, 50, 100, 150, 200]
  .map(m => ({ value: m, label: `${m}km` }))

const valuetext = (value) => {
  return `${value} km`
}

const SurveyRadius = ({
  label,
  value = 75,
  onChange = () => undefined,
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.root}>
      <FormLabel>{label}</FormLabel>

      <Slider
        className={classes.slider}
        getAriaValueText={valuetext}
        aria-labelledby="radius-slider"
        defaultValue={75}
        value={value}
        min={10}
        max={200}
        step={10}
        valueLabelDisplay="auto"
        marks={marks}
        onChange={(event, value) => onChange(value)}
      />
    </FormControl>
  )
}

export default SurveyRadius

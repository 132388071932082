import React, { useEffect } from 'react'

// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Divider,
} from '@material-ui/core'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'
import { resolveVariantText } from 'data/store/utils/survey-utils'

import SurveyLocation from 'ui/components/molecules/SurveyLocation'
import SurveyRadius from 'ui/components/molecules/SurveyRadius'
import SurveyRadioGroup from 'ui/components/molecules/SurveyRadioGroup'
import SurveyCategories from 'ui/components/molecules/SurveyCategories'
import SurveyInterests from 'ui/components/molecules/SurveyInterests'
import SurveyScaleEval from 'ui/components/molecules/SurveyScaleEval'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    margin: theme.spacing(3, 0),
  },
}))

const Question = observer(({ pathway, question }) => {
  const { track } = useAnalytics()
  const store = useStore()
  const { tenantConfig } = useConfig()

  const questionText = resolveVariantText(question, 'question', pathway)

  // survey questions default to set(key, value) using question id

  const handleChange = value => {
    track('surveyQuestionChange', {
      category: 'Survey',
      label: question.id,
    })

    store.survey.set(question.id, value)
  }

  switch (question.type) {
    case 'location': return (
      <SurveyLocation
        defaultLocation={tenantConfig.search?.location?.defaultLocation}
        label={questionText}
        // value={toJS(store.survey[question.id])}
        // onChange={handleChange}
      />
    )

    case 'radius': return (
      <SurveyRadius
        label={questionText}
        value={store.survey[question.id]}
        onChange={handleChange}
      />
    )

    case 'radio': return (
      <SurveyRadioGroup
        name={question.id}
        label={questionText}
        value={store.survey[question.id]}
        onChange={handleChange}
        options={question.options}
        deselect
        row
      />
    )

    case 'categories': return (
      <SurveyCategories
        question={question}
        label={questionText}
        metaCategoryMap={store.tenant.metaCategoryMap}
        selected={store.survey[question.id] ? store.survey[question.id].slice() : []}
        onChange={handleChange}
      />
    )

    case 'interests': return (
      <SurveyInterests
        question={question}
        pathway={pathway}
        selected={store.survey[question.id] ? store.survey[question.id].slice() : []}
        onChange={handleChange}
      />
    )

    case 'scaleeval': return (
      <SurveyScaleEval
        question={question}
      />
    )

    default: return null
  }
})

const SurveyStep = ({ pathway, questions, stepConfig }) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const questionProps = stepConfig.questions
    .filter(q => 'id' in q && q.id in questions)
    .map(q => ({
      pathway,
      question: questions[q.id],
    }))

  const stepTitle = resolveVariantText(stepConfig, 'title', pathway)

  useEffect(() => {
    track(`surveyStepChange`, {
      category: 'Survey',
      label: stepTitle,
    })
  }, [stepConfig, stepTitle, track])

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>{stepTitle}</Typography>
      <Divider />
      <br />

      {questionProps.map((q, i) => (
        <div key={i} className={classes.question}>
          <Question
            {...q}
          />
       </div>
      ))}

      {/* <pre>
        {JSON.stringify(stepConfig, null, 2)}
      </pre> */}
    </div>
  )
}

export default SurveyStep

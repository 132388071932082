import { autorun, toJS } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import { analytics } from 'data/analytics/analytics'
import { useConfig } from 'config/config'

import { Tenant } from './Tenant'
import { SearchControl } from './SearchControl'
import { Survey } from './Survey'

const { appConfig, tenantConfig } = useConfig()

export const RootStore = types
  .model({
    version: appConfig.version || 0,
    tenant: types.optional(Tenant, {}),
    // params: types.optional(SearchParams, {}),
    search: types.optional(SearchControl, {}),
    survey: types.optional(Survey, {}),
  })

  .views(self => ({
    get saveData() {
      const sd = {
        version: self.version,
        params: {
          ...self.search.params,
          query: '',
        },
        ui: self.search.ui,
        survey: self.survey,
        favourites: self.search.favouritesSearch?.results,
      }

      return sd
    },
  }))

  .actions(self => ({
    postInit: flow(function* postInit() {

      self.search.loadDefaultFilters()
      self.tenant.loadVolatile()

      if (tenantConfig.commissionedServiceLookup) {
        yield self.tenant.loadCommissionedServices(tenantConfig.commissionedServiceLookup)
      }

      self.search.initLocation()
      self.search.loadContentRegions(tenantConfig.contentRegions || [])

      // search on params change

      autorun(() => {
        self.search.initSearch(
          // args are observed but not used
          toJS(self.search.params),
          toJS(self.search.ui.showAllCategories),
        )
      })

      // save user data

      autorun(() => {
        const psjson = JSON.stringify(toJS(self.saveData), null, 2)
        window.localStorage.setItem(`ams-userstore-${appConfig.tenant || 'default'}`, psjson)
      }, {
        delay: 1000,
      })
    }),

    resetAll() {
      analytics.track('resetAll', {
        categories: 'Navigation',
        label: 'Reset All',
      })
      analytics.reset()

      self.survey = {}
      self.search = {
        searches: tenantConfig.search.searches,
        params: {},
        ui: {},
      }

      self.search.initLocation()
    },

    resetSurvey() {
      self.survey = {}
    },
  }))

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useConfig } from 'config/config'

import Headers  from 'ui/components/molecules/Headers'
import * as amsComponents from 'ui/components'

const routeComponents = {
  ...amsComponents,
  Redirect,
}

const resolveComponent = name => {
  return name in routeComponents ? routeComponents[name] : undefined
}

const TenantRoutes = () => {
  const { tenantConfig } = useConfig()

  const routes = tenantConfig.routes.map((r, i) => {

    const RouteComponent = resolveComponent(r.component)
    const ContainerComponent = resolveComponent(r.container)

    return (
      <Route
        key={i}
        exact={!!r.exact}
        path={r.path}
      >
        {ContainerComponent ? (
          <ContainerComponent {...r.containerProps}>
            <RouteComponent {...{...r.props}} />
          </ContainerComponent>
        ) : (
          <RouteComponent {...{...r.props}} />
        )}
      </Route>
    )
  })

  return (
    <>
      <Headers />
      <Switch>
        {routes}
      </Switch>
    </>
  )
}

export default TenantRoutes

import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import scrollIntoView from 'scroll-into-view-if-needed'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fafafa',
    margin: theme.spacing(2, 0),
    // boxShadow: 'none',
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.12)',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(5),
  },
}))

const MarkdownAccordion = ({
  summaryText,
  expanded,
  onAccordionClick,
  children,
}) => {
  const classes = useStyles()

  const id = summaryText
    .toLowerCase()
    .replace(/([^a-zA-Z0-9]+)/g, '_')
    .replace(/_$/g, '')

  useEffect(() => {
    if (id !== expanded) return

    const el = document.getElementById(`${id}_top`)
    if (!el) return

    setTimeout(() => {
      scrollIntoView(el, {
        scrollMode: 'always',
        behavior: 'smooth',
        block: 'start',
      })
    }, 100)

  }, [expanded, id])

  return (
    <>
      <div id={`${id}_top`} />
      <Accordion
        classes={{
          root: classes.root,
        }}
        expanded={expanded === id}
        onChange={() => onAccordionClick(id)}
        square
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={id}
          id={id}
        >
          <Typography variant="h6" className={classes.title}>
            {summaryText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default MarkdownAccordion

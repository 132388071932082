import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Slider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 3),
  },
}))

const marks = [10, 50, 100, 150, 200]
  .map(m => ({ value: m, label: `${m}km` }))

const valuetext = (value) => {
  return `${value} km`
}

const RadiusSlider = ({
  value = 0,
  onChange = () => undefined,
}) => {
  const classes = useStyles()
  const [internalValue, setInternalValue] = useState(0)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const handleChange = (event, value) => {
    setInternalValue(value)
  }

  const handleChangeCommitted = (event, value) => {
    console.log('bang', value)
    onChange(value)
  }

  return (
    <div className={classes.root}>
      <Slider
        // className={classes.margin}
        getAriaValueText={valuetext}
        aria-labelledby="radius-slider"
        defaultValue={75}
        value={internalValue}
        min={10}
        max={200}
        step={10}
        valueLabelDisplay="auto"
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
    </div>
  )
}

export default RadiusSlider

import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'

import { useConfig } from 'config/config'
import { migrations } from './storage-migrations'
import { loadMarkdown } from '../lib/markdown'

const { appConfig, tenantConfig } = useConfig()

// preload markdown content

const markdownContent = keyBy(loadMarkdown({ tenant: appConfig.tenant }), 'id')

// initial user data

const defaultUserData = {
  version: appConfig.version || 0,
  params: {},
  survey: {},
  ui: {},
}

// load from store

const loadStoredUserData = () => {

  const storedUserData = JSON.parse(
    window.localStorage.getItem(`ams-userstore-${appConfig.tenant || 'default'}`)
  )

  if (!storedUserData) return null

  // run migrations

  const prev = storedUserData.version || 0
  const curr = appConfig.version || 0

  const mud = migrations
    .filter(m => (m.version === -1) || (m.version > prev && m.version <= curr))
    .reduce((data, curr) => {
      // console.log(`Migrating: ${curr.info}`)
      return curr.migrate({ userData: data }) || data
    }, storedUserData) || defaultUserData

  const iud = cloneDeep({
    ...mud,
    version: curr,
  })

  return iud
}

const buildStoreConfig = userData => ({
  search: {
    searches: tenantConfig.search.searches,
    params: userData.params,
    ui: userData.ui,
  },
  survey: userData.survey,
  tenant: {
    categories: tenantConfig.categories,
    options: tenantConfig.options,
    content: {
      items: markdownContent,
    },
  },
})


export const initStoreConfigs = () => {
  const storedUserData = loadStoredUserData()

  return {
    defaultConfig: buildStoreConfig(cloneDeep(defaultUserData)),
    userConfig: storedUserData ? buildStoreConfig(storedUserData) : null,
  }
}

import React from 'react'

import { NavLink } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Box,
} from '@material-ui/core'

import { useConfig } from 'config/config'

const { tenantConfig } = useConfig()

const bb = 'sm'

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#f0f',
    width: '100%',
    margin: theme.spacing(5, 0),
  },
  container: {
    // backgroundColor: '#f00',
    // maxWidth: 800,
    margin: '0 auto',
    // padding: theme.spacing(2),
  },
  navLink: {
    textDecoration: 'none',
  },
  card: {
    border: '1px solid #eee',
  },
  buttonContainer: {
    // backgroundColor: '#0f0',
    padding: theme.spacing(5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 280,

    // borderRadius: 4,
    // borderWidth: 1,
    // borderColor: theme.palette.secondary.light,
    // borderStyle: 'solid',

    [theme.breakpoints.down(bb)]: {
      minHeight: 'auto',
      padding: theme.spacing(3, 2),

    },
  },
  button: {

  },
  buttonIcon: {
    marginTop: theme.spacing(2),
    // borderWidth: 3,
    // borderStyle: 'solid',
    // borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
    // backgroundColor: color(theme.palette.secondary.light)
    //   .lighten(0.2)
    //   .string(),
    color: theme.palette.secondary.contrastText,

    borderRadius: '50%',
    // minWidth: '1rem',
    // padding: 24,

    fontSize: 38,
    width: 100,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > i': {
      marginTop: 3,
    },

    [theme.breakpoints.down(bb)]: {
      marginTop: 0,
      fontSize: 20,
      width: 44,
      height: 44,

    },


  },
  buttonLabel: {
    marginTop: theme.spacing(4),
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,

    [theme.breakpoints.down(bb)]: {
      marginTop: theme.spacing(2),
      fontSize: 18,
    },
  },
}))

const HomeButton = ({ button }) => {
  const classes = useStyles()
  const { track } = useAnalytics()

  const handleClick = () => {
    track('homePageButtonClick', {
      category: 'Navigation',
      label: button.label,
    })
  }

  return (
    <div className={classes.button}>
      <NavLink
        to={button.route}
        className={classes.navLink}
        onClick={handleClick}
      >
        <Box
          // className={classes.card}
          border={1}
          borderColor="grey.300"
          // boxShadow={1}
          borderRadius={12}
          // variant="outlined"
          // raised
        >
          <div className={classes.buttonContainer}>

            <div className={classes.buttonIcon}>
              <i className={clsx(button.icon, 'fa-fw')} />
            </div>
            <div className={classes.buttonLabel}>
              {button.label}
            </div>

          </div>
        </Box>
      </NavLink>
    </div>
  )

}

const HomeButtons = () => {
  const classes = useStyles()
  const config = tenantConfig.homeButtons

  if (!config) return null

  const tprops = config.props || {}

  const gridProps = {
    xs: 12,
    md: 6,
    ...tprops,
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          {tenantConfig.homeButtons.buttons.map((button, i) => (
            <Grid item key={i} {...{...gridProps}}>
              <HomeButton button={button} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default HomeButtons

import React from 'react'

import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Typography,
  Divider,
  Grid,
  Link as MuiLink,
} from '@material-ui/core'

import FooterMarkdown from 'ui/components/atoms/FooterMarkdown'

import { useConfig } from 'config/config'
import { useAnalytics } from 'use-analytics'

const col1 = `
<imgasset srcId="cieLogo" style="maxWidth: 100px; opacity: 0.76;" />
<br />
<br />

Powered by the [Community Information Exchange](https://www.communityinformationexchange.com.au/)

© 2020 My Community Directory

All rights reserved. ABN: 18 136 511 512
`
const useStyles = makeStyles(theme => ({
  footer: props => ({
    padding: theme.spacing(4, 2, 6),
    // marginTop: 'auto',
    // marginBottom: 0,
    backgroundColor: props.backgroundColor || theme.palette.primary.main,
    color: theme.palette.text.primary,
  }),
  cie: {
    padding: theme.spacing(4, 2),
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 2),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    // marginBottom: theme.spacing(4),
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  mdContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

  },
  link: {
    fontSize: 16,
    ...theme.typography.body1,
    // fontSize: 16,
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5, 0),
  },
  linkIcon: {
    marginRight: theme.spacing(1),
  },
}))

const FooterLink = ({
  icon,
  label,
  route,
  href,
  ...innerProps
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const handleClick = () => {
    track('footerMenuItemClick', {
      category: 'Navigation',
      label: label,
    })
  }

  if (href) return (
    <MuiLink
      className={classes.link}
      underline="none"
      href={href}
      target="_blank"
    >
      <div className={classes.linkContainer}>
        <div className={classes.linkIcon}>
          <i className={clsx(icon, 'fa-fw', classes.linkIcon)} />
        </div>
        <div>
          {label.split('\n').map((l, i) => (
            <div key={i}>{l}</div>
          ))}
          {/* {label} */}
        </div>
      </div>
    </MuiLink>

  )

  if (route) return (
    <MuiLink
      className={classes.link}
      underline="none"
      component={RouterLink}
      to={route}
      onClick={handleClick}
    >
      {/* {label} */}
      <i className={clsx(icon, 'fa-fw', classes.linkIcon)} />{label}
    </MuiLink>
  )
}

const Menu = ({
  title,
  items,
}) => {
  const classes = useStyles()

  if (!items) return null

  return (
    <div className={classes.menu}>
      <Typography
        className={classes.title}
        variant="h6"
        gutterBottom
      >
        {title}
      </Typography>
      <Divider />
      <div className={classes.menuItems}>
        {items
          .filter(m => m.footer)
          .map(({ footer, ...m }, i) => (
            <FooterLink key={i} {...m} />
            // <div key={i}>
            //   {m.label}

            // </div>
        ))}
      </div>
    </div>
  )
}

const Footer = () => {
  const { tenantConfig } = useConfig()
  const fc = tenantConfig.footer

  const classes = useStyles(tenantConfig.footer || {})

  if (!fc) return null

  const columns = new Set(fc.columns)

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">

        <Grid
          // spacing={0}
          container
        >
          {columns.has('cie') &&
            <Grid
              item
              xs={12}
              md={4}
            >
              <div className={classes.cie}>
                <FooterMarkdown>
                  {col1}
                </FooterMarkdown>
              </div>
            </Grid>
          }

          {columns.has('menu') &&
            <Grid
              item
              xs={12}
              md={4}
            >
              <Menu title="Menu" items={tenantConfig.menu} />
            </Grid>
          }

          {columns.has('contact') &&
            <Grid
              item
              xs={12}
              md={4}
            >
              <Menu title="Contact" items={tenantConfig.contact} />
            </Grid>
          }
        </Grid>


        {/* <Typography variant="body1">© {new Date().getFullYear()} Mindcare Gladstone</Typography> */}
      </Container>
    </footer>
  )
}

export default Footer

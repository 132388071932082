import React from 'react'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { useStore } from 'data/store/store'

import LocationInput from 'ui/components/molecules/LocationInput'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}))

const SurveyLocation = observer(({
  label = null,
  // value = null,
  defaultLocation = null,
  // onChange = () => undefined,
  mode = 'survey',
}) => {
  const classes = useStyles()
  const store = useStore()
  const storeNode = store.survey

  const isDefault = !!(storeNode.location && storeNode.location.default)

  const handleDefault = (event, checked) => {
    storeNode.setLocation(null)

    if (checked)
      storeNode.setLocation(defaultLocation)
  }

  const handleChange = value => {
    storeNode.setLocation(value)
  }

  return (
    <FormControl className={classes.root}>
      <FormLabel>{label}</FormLabel>

      <br />
      <LocationInput
        id="location-select-survey"
        disabled={isDefault}
        value={storeNode.location}
        onChange={handleChange}
        buttonLabel
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={isDefault}
            onChange={handleDefault}
            name="default-location"
          />
        }
        label="I do not want to provide a location"
      />


    </FormControl>
  )
})

export default SurveyLocation

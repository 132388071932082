import { types, getRoot } from 'mobx-state-tree'

import { formatDistance } from '../utils/store-utils'

export const Event = types
  .model({
    type: types.literal('event'),
    // id: types.refinement(types.identifier, identifier => identifier.indexOf('events_') === 0),
    id: '',
    search: '',
    name: '',
    day: '',
    startDay: '',
    startMonth: '',
    time: '',
    address: '',
    phone: types.maybeNull(types.string, null),
    description: '',
    url: '',
    lat: 0.0,
    lng: 0.0,
    distance: 0.0,
    serviceChanges: types.maybeNull(types.model({
      openingHours: '',
      serviceChangesOrClosures: '',
      extraServicesOrEvents: '',
      emergencyContact: '',
      eligibilityConditions: '',
    })),
  })

  .views(self => ({
    get geometry() {
      return ({
        lat: self.lat,
        lng: self.lng,
      })
    },

    get distanceLabel() {
      return formatDistance(self.distance)
    },

    get dateString() {
      if (!(self.day && self.startDay && self.startMonth)) return null

      return `${self.day} ${self.startDay} ${self.startMonth}`
    },

    get isFavourite() {
      return getRoot(self).search.isFavourite(self.id)
    },

  }))

import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Divider,
  Button,
} from '@material-ui/core'

import SimpleMarkdown from 'ui/components/atoms/SimpleMarkdown'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  hints: {
    marginTop: theme.spacing(5),
    // padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hintLabel: {
    // marginRight: theme.spacing(2),
    fontSize: 16,
  },
  hintButton: {
    margin: theme.spacing(0, 1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const Information = observer(() => {
  const classes = useStyles()

  const { tenantConfig } = useConfig()
  const tabs = tenantConfig.search.tabs

  const store = useStore()
  const info = store.search.selectedInformation
  const category = store.search.selectedInformationCategory

  const [hints, setHints] = useState(null)

  // tab hints

  useEffect(() => {
    const thints = !category.search
      ? null
      : tabs
        .map((tab, index) => ({ tab, index }))
        .filter(t => t.tab.options?.tabHint)

    setHints(thints)
  }, [category.search, tabs])

  const handleHintButtonClick = (tab) => {
    store.search.ui.setSelectedTab(tab)
  }

  const HintButtons = ({ hints }) => {
    return (
      <div className={classes.hints}>
        <Typography
          className={classes.hintLabel}
          variant="body2"
        >
          {/* <i className={clsx('fal fa-lightbulb', classes.icon)} /> */}
          Search for
        </Typography>
        {hints.map((h, i) => (
          <Button
            className={classes.hintButton}
            key={i}
            variant="outlined"
            color="primary"
            size="small"
            disableElevation
            onClick={() => handleHintButtonClick(h.index)}
          >
            <i className={clsx(h.tab.icon, classes.icon)} />
            {h.tab.title}
          </Button>
        ))}
        <Typography
          className={classes.hintLabel}
          variant="body2"
        >
          in {category.id === 'default' ? 'all categories' : 'this category'}.
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Typography
        className={classes.title}
        variant="h4"
        gutterBottom
      >
        {info.title}
      </Typography>
      <Divider />
      <br />
      <SimpleMarkdown>{info.body}</SimpleMarkdown>
      {/* <br /> */}
      {hints && <HintButtons hints={hints} />}
    </>
  )

})

export default Information

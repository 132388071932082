import { surveyShared } from '../shared/survey'

import keyBy from 'lodash/keyBy'

const kprereq = ['interests', 'contains', [
  'isolation',
  'event',
  'stress',
  'feelings',
]]

const kcatmap = {
  'early': {
    categories: ['k-early'],
  },
  'mild': {
    categories: ['k-mild'],
  },
  'moderate': {
    categories: [
      'k-moderate',
      'mental-health-practitioners',
      'inpatient-mental-health-services',
    ],
  },
  'severe': {
    categories: [
      'k-severe',
      'emergency-services',
      'mental-health-practitioners',
      'inpatient-mental-health-services',
    ],
  },
}

const questions = [
  ...surveyShared.questions,
  {
    id: 'categories',
    type: 'categories',
    label: 'Categories',
    icon: 'far fa-stream',
    question: {
      self: 'What are you interested in?',
      other: 'What are they interested in?',
    },
    // categoryGroups: [
    //   {
    //     title: 'Services',
    //     categories: [
    //       'emergency-services',
    //       'general-practitioners',
    //       'mental-health-practitioners',
    //       'social-support-services',
    //     ],
    //   },
    //   {
    //     title: 'Information',
    //     categories: [
    //       'online-resources-and-information',
    //     ],
    //   },
    // ],
  },
  {
    id: 'interests',
    type: 'interests',
    label: 'Interests',
    question: null,
    interests: [
      {
        id: 'isolation',
        question: {
          self: 'I\'m feeling lonely and/or isolated',
          other: 'They\'re feeling lonely and/or isolated',
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'mental-health-support-lines',
          'social-support-services',
        ],
      },
      {
        id: 'substance',
        question: {
          self: 'I\'m concerned about my alcohol and/or other drugs usage',
          other: 'They\'re concerned about their alcohol and/or other drugs usage',
        },
        categories: [
          'alcohol-and-drug-support',
        ],
      },
      {
        id: 'gambling',
        question: {
          self: 'I\'m concerned about my gambling',
          other: 'They\'re concerned about their gambling',
        },
        categories: [
          'gambling',
        ],
      },
      {
        id: 'event',
        question: {
          self: 'I have gone through an upsetting event which I would like help with',
          other: 'They have gone through an upsetting event which they would like help with',
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'suicide-bereavement-support',
        ],
      },
      {
        id: 'stress',
        question: {
          self: 'I\'m feeling down, stressed and/or can\'t stop worrying',
          other: 'They\'re feeling down, stressed and/or can\'t stop worrying',
        },
        categories: [
          'mental-health-practitioners',
          // 'child-and-youth-mental-health',
          'mental-health-peer-support',
          'social-support-services',
        ],
      },
      {
        id: 'feelings',
        question: {
          self: 'I\'d like to talk to someone about my emotions/feelings',
          other: 'They\'d like to talk to someone about their emotions/feelings',
        },
        categories: [
          'mental-health-practitioners',
          'mental-health-peer-support',
          // 'child-and-youth-mental-health',
        ],
      },
    ],
  },
]

export const survey = {
  questions,

  // TODO: move this and the merge to the config loader
  questionsMap: keyBy(questions, 'id'),

  defaults: {
    categories: [
      // 'child-and-youth-mental-health',
      // 'emergency-services',
      // 'lgbtqi',
    ],
  },

  pathways: [
    {
      pathways: ['self', 'other'],
      steps: [
        {
          title: 'Location',
          questions: [
            { id: 'location' },
            { id: 'radius' },
          ],
          validation: [
            ['location', 'neq', null],
          ],
        },
        {
          title: {
            self: 'About You',
            other: 'About Them',
          },
          questions: [
            { id: 'age' },
            { id: 'gender' },
            { id: 'atsi' },
            { id: 'sexuality' },
          ],
          mapCategories: [
            {
              question: 'age',
              categories: {
                '0 - 15': { categories: [ 'child-and-youth-mental-health'] },
              },
            },
            {
              question: 'atsi',
              categories: {
                'Yes': { categories: ['atsi'] },
              },
            },
            {
              question: 'sexuality',
              categories: {
                'Yes': { categories: ['lgbtqi'] },
              },
            },

          ],
        },
        {
          title: {
            self: 'What would like help with?',
            other: 'What would they like help with?',
          },
          questions: [
            { id: 'interests' },
          ],
          mapCategories: [
            { question: 'interests' },
          ],
        },
        {
          title: 'How are you feeling?',
          questions: [
            { id: 'k5' },
          ],
          when: [
            ['pathway', 'eq', 'self'],
            ['atsi', 'eq', 'Yes'],
            kprereq,
          ],
          mapCategories: [
            {
              question: 'k5',
              categories: kcatmap,
            },
          ],
        },
        {
          title: 'How are you feeling?',
          questions: [
            { id: 'k10' },
          ],
          when: [
            ['pathway', 'eq', 'self'],
            ['atsi', 'neq', 'Yes'],
            kprereq,
          ],
          mapCategories: [
            {
              question: 'k10',
              categories: kcatmap,
            },
          ],
        },
      ],
    },
  ],
  categoryMapping: [
    {
      type: 'interests',
      question: 'interests',
    },
  ],



}

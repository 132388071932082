import humps from 'lodash-humps'
// import flatten from 'lodash/flatten'
// import orderBy from 'lodash/orderBy'

import { client } from './cie-api'

export const getCommissionedServicesByOutlet = async outletId => {
  const result = await client.get(`/commissionedservices/outlet/${outletId}`)

  if (!result.data?.data || !result.data?.success) return null

  return humps(result.data.data)
}

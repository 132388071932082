// import isEqual from 'lodash/isEqual'

import { toJS, isObservableArray } from 'mobx'
import { types, getRoot, getParent } from 'mobx-state-tree'

import { Location } from './Location'
import { Geography } from './Geography'

import { useConfig } from 'config/config'
import { analytics } from 'data/analytics/analytics'
const { tenantConfig } = useConfig()

const DateRange = types
  .model({
    // -2 custom, -1 anytime, 0+ days
    delta: -1,
    start: types.maybeNull(types.Date, null),
    end: types.maybeNull(types.Date, null),
  })

export const SearchParams = types
  .model({
    pathway: 'self',
    query: '',
    radius: 75,
    // location: types.maybeNull(Location, null),

    location: types.maybeNull(types.union(
      Location,
      Geography,
    )),
    dateRange: types.optional(DateRange, {}),
    // showAllCategories: false,
    categories: types.optional(types.array(types.string), []),
    selectedCategories: types.union(
      types.array(types.string),
      types.maybeNull(types.string, null),
    ),
    age: types.maybeNull(types.string, null),
    gender: types.maybeNull(types.string, null),
    sexuality: types.maybeNull(types.string, null),
    atsi: types.maybeNull(types.string, null),
  })

  .views(self => ({
    isPreferredCategory(id) {
      return self.categories.includes(id)
    },

    get tenant() {
      return getRoot(self).tenant
    },

    get ui() {
      return getParent(self).ui
    },

    get visibleCategories() {
      if (!self.ui.showAllCategories && self.categories.length > 0) {
        return self.tenant.categories
          .filter(c => c.show !== 'never')
          .filter(c => self.isPreferredCategory(c.id))
      }

      return self.tenant
        .categories
        .filter(c =>
          !(c.show === 'preferred'
          && !self.isPreferredCategory(c.id))
        )
        .filter(c => c.show !== 'never')
    },

    get selectedCategoriesMeta() {
      if (typeof self.selectedCategories === 'string') {
        const sc = self.tenant.metaCategoryMap[self.selectedCategories]
        return [sc]
      }

      if (isObservableArray(self.selectedCategories)) {
        const sc = self.selectedCategories.map(sc => getRoot(self).tenant.metaCategoryMap[sc])
        return sc
      }

      return []
    },

    get isSearchCategorySelected() {
      const opt = self.tenant.options
      if (opt.categoryMode === 'multiple') return true
      if (opt.categoryMode === 'single' && !opt.informationCategories) return true
      if (!self.selectedCategories) return true
      // console.log(toJS(self.selectedCategories))
      return self.selectedCategoriesMeta.some(c => c.search)
    },

    isLocationParamsSelected(params) {
      // // no location
      // if (!params.locality && !self.location) return true

      // // restore location
      // if (!params.locality && self.location) return false


      // change location
      return (
        params?.state === self.location?.stateUrl &&
        params?.council === self.location?.councilUrl &&
        params?.locality === self.location?.url
      )
    },
  }))

  .actions(self => ({
    set(key, value) {
      if (key === 'location') {
        // console.log('setting location')
        self.setLocation(value)
        return
      }

      if (key in self) {
        self[key] = value
      }
    },

    setQuery(query) { self.query = query },
    setRadius(radius) { self.radius = radius },
    setLocation(location) { self.location = location },

    setSelectedCategories(selected) {
      const s = toJS(selected)
      const mcm = getRoot(self).tenant.metaCategoryMap

      if (typeof s === 'string') {
        self.selectedCategories = s in mcm ? s : null
      } else if (Array.isArray(s)) {
        const sf = s.filter(s => s in mcm)
        self.selectedCategories = sf.length > 0 ? sf : null
      } else {
        self.selectedCategories = null
      }
    },

    updateFromSurvey(survey) {
      self.selectedCategories = null
      self.query = ''
      self.showAllCategories = false

      // console.log(toJS(survey))

      Object.keys(survey).forEach(k => {
        if (k in self) {
          self[k] = toJS(survey[k])
        }
      })

      // autoselect one category

      if (!self.selectedCategories &&
        tenantConfig.search?.survey?.autoSelectSingleCategory &&
        self.categories?.length === 1
        ) {
        const cid = self.categories[0]
        self.selectedCategories = tenantConfig.options.categoryMode === 'single' ? cid : [cid]
      }

      analytics.track('surveyImplUpdateParamsFromSurvey', {
        category: 'Survey',
        label: 'Update Params',
        // params
      })
    },
  }))

import React, { useEffect, useCallback } from 'react'

import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
// import { toJS } from 'mobx'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  CircularProgress,
  Button,
  Typography,
 } from '@material-ui/core'

import scrollIntoView from 'scroll-into-view-if-needed'

import SearchResult from 'ui/components/molecules/SearchResult'
import MarkdownResultsHeader from 'ui/components/molecules/MarkdownResultsHeader'

import { useStore } from 'data/store/store'
// import { useConfig } from 'config/config'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  progress: {
    margin: '40px auto',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
 },
  loadMore: {
    margin: '20px auto',
  },
  navLink: {
    textDecoration: 'none',
  },
}))

const SearchResults = observer(({ searchId }) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const store = useStore()
  const search = store.search.searches.get(searchId)
  // const [suspendScroll, setSuspendScroll] = useState(false)
  const isFavourites = search.type === 'favourites'

  const headerInformation = search.resultsHeader?.prefix
    ? store.search.selectedInformation(search.resultsHeader.prefix)
    : null

  useEffect(() => {
    const el = document.getElementById(`search-results-top-${searchId}`)

    scrollIntoView(el, {
      scrollMode: 'always',
      block: 'start',
    })
  }, [store.search.searchCount, searchId])

  useEffect(() => {
    if (!store.search.selectedResult ||
      store.search.selectedResult.source === 'results') return

    const el = document.getElementById(store.search.selectedResult.id)
    if (!el) return

    scrollIntoView(el, {
      scollMode: 'always',
      behavior: 'smooth',
      block: 'start',
    })
  }, [store.search.selectedResult])

  const handleClickResult = useCallback(result => {
    // setSuspendScroll(true)
    track('searchResultsResultClick', {
      category: 'SearchResults',
      label: result.name || 'unknown',
    })

    store.search.setSelected('results', result)
  }, [store.search, track])

  const handleClickOutside = useCallback(e => {
    track('searchResultsResultClickOutside', {
      category: 'SearchResults',
      label: 'Click Outside',
    })

    e.stopPropagation()
    store.search.setSelected(null)
  }, [store.search, track])

  const handleClickSiteSupport = useCallback(() => {
    track('searchResultsButtonClick', {
      category: 'SearchResults',
      label: 'Site Support',
    })
  }, [track])

  return (
    <div
      className={classes.root}
      onClick={handleClickOutside}
    >
      <div id={`search-results-top-${searchId}`}></div>

      {!store.search.params.location && (
        <div className={classes.actionContainer} data-nosnippet>
          <Typography variant="body1" align="center">
            Welcome to Access My Healthcare.<br/>
            To begin searching for services and events in your region, please select a location in the search settings.
          </Typography>
        </div>
      )}

      {headerInformation && !search.loading && (
        <MarkdownResultsHeader
          config={search.resultsHeader}
          content={headerInformation}
        />
      )}

      {search.results.map((r, i) => (
        <SearchResult
          key={i}
          search={search}
          data={r}
          selected={r.id === store.search.selectedId}
          onClickResult={handleClickResult}
        />
      ))}

      {search.error && !search.loading && (
        <div className={classes.actionContainer} data-nosnippet>
          <Typography variant="body1">
            There was an error fetching results.
          </Typography>

          <Button
            className={classes.loadMore}
            variant="outlined"
            onClick={() => search.tryAgain()}
          >
            Try Again
          </Button>
        </div>
      )}

      {search.canLoadMore && !search.loading && (
        <div className={classes.actionContainer} data-nosnippet>
          <Button
            className={classes.loadMore}
            variant="outlined"
            onClick={() => search.loadMore()}
          >
            Load More
          </Button>
        </div>
      )}

      {!search.canLoadMore && !search.loading && !search.error && (
        <>
          {!isFavourites && (
            <div className={classes.actionContainer} data-nosnippet>
              <Typography variant="body1">
                {search.results.length} Result{search.results.length > 1 && 's'} Found
              </Typography>
            </div>
          )}

          {isFavourites && search.results.length === 0 && (
            <div className={classes.actionContainer} data-nosnippet>
              <Typography variant="body1" align="center">
                You have no results in your favourites list.<br/><br/>
                Add items by clicking the <i className="far fa-heart" /> icon in the search results.
              </Typography>
            </div>
          )}

          {isFavourites && search.results.length > 0 && (
            <div className={classes.actionContainer} data-nosnippet>
              <Typography variant="body1">
                {search.results.length} Item{search.results.length > 1 && 's'}
              </Typography>
            </div>
          )}
        </>
      )}

      {search.loading && (
        <CircularProgress
          size={32}
          thickness={3.0}
          className={classes.progress}
        />
      )}

      {!search.loading && search.showSiteSupport && (
        <div className={classes.actionContainer} data-nosnippet>
          <Typography variant="body1">
            Can&apos;t find what your looking for?
          </Typography>
          <br />
          <NavLink
            to="/support"
            className={classes.navLink}
            onClick={handleClickSiteSupport}
          >
            <Button
              variant="outlined"
              color="primary"
            >
              <Box mr={1}>
                <i className="fas fa-question" />
              </Box>
              Contact Site Support

            </Button>
          </NavLink>
        </div>
      )}


    </div>
  )
})

export default SearchResults

import React from 'react'
import { useStore } from 'data/store/store'
import { observer } from 'mobx-react-lite'

export const useSelectedTab = observer(() => {
  const store = useStore()
  // const [tab, setTab] = useState(0)

  const setTab = tab => {
    store.search.ui.tab = tab
  }

  return [store.search.ui.tab, setTab]
})

window.useSelectedTab = useSelectedTab

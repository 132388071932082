import React, { useEffect, useState, useCallback } from 'react'

// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import SurveyRadioGroup from 'ui/components/molecules/SurveyRadioGroup'

import { useStore } from 'data/store/store'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    margin: theme.spacing(3, 0),
  },
}))

const SurveyScaleEval = observer(({
  question,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const store = useStore()

  const [scaleEval] = useState(store.survey.scaleEval.get(question.id))

  useEffect(() => {
    track('surveyScaleEvalStart', {
      category: 'Survey',
      label: question.id,
    })
  }, [question, track])

  const trackScaleEvalComplete = useCallback(() => {
    track('surveyScaleEvalComplete', {
      category: 'Survey',
      label: question.id,
    })
  }, [track, question.id])

  useEffect(() => {
    if (!scaleEval.complete) return
    trackScaleEvalComplete()
  }, [scaleEval.complete, trackScaleEvalComplete])

  const handleQuestionChange = (index, value) => {
    track('surveyScaleEvalChange', {
      category: 'Survey',
      label: `Question ${index}`,
    })

    scaleEval.setValue(index, +value)
  }

  if (!scaleEval) {
    return <div>Undefined scale eval.</div>
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1">{question.summary}</Typography>

      {question.questions.map((q, i) => {
        return (
          <div key={i} className={classes.question}>
            <SurveyRadioGroup
              name={`${question.id}-${i}`}
              label={q.label}
              value={scaleEval.values[i]}
              options={question.options}
              labelField="label"
              valueField="value"
              onChange={v => handleQuestionChange(i, v)}
              row
            />
          </div>
        )
      })}

      {false && (
        <div>
          [total: {scaleEval.evalScore.total}, score: {scaleEval.evalScore.score}, complete: {scaleEval.complete ? 'true' : 'false'}]
        </div>
      )}



    </div>
  )
})

export default SurveyScaleEval

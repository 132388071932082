import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Portal } from 'react-portal'

import {
  makeStyles,
  withStyles,
  useTheme,
} from '@material-ui/core/styles'
import {
  Container,
  Box,
  SwipeableDrawer,
  useMediaQuery,
} from '@material-ui/core'

import { useStore } from 'data/store/store'
import { useConfig } from 'config/config'
import { usePlatform } from 'ui/hooks/usePlatform'
import { useSelectedTab } from 'ui/hooks/store-hooks'


import LocationDialog from 'ui/components/molecules/LocationDialog'
import DesktopSearchToolbar from 'ui/components/molecules/DesktopSearchToolbar'
import InfoOnly from 'ui/components/molecules/InfoOnly'
import SearchParameters from '../organisms/SearchParameters'
import SearchResults from '../organisms/SearchResults'
import SearchResultsMap from '../molecules/SearchResultsMap'
import Information from '../molecules/Information'

const paramatersWidth = 360
const resultsWidth = 500

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  hidden: {
    display: 'none !important',
  },
  content: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  tabPanel: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  paramaterDrawer: {
    minWidth: paramatersWidth,
    maxWidth: paramatersWidth,
    overflowX: 'hidden',
  },
  parameters: {
    width: paramatersWidth,
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  },
  results: {
    width: resultsWidth,
  },
  informationOnly: {
    flex: '1 0 auto',
  },
  map: {
    flex: '1 0 auto',
  },
})

const useStyles = makeStyles(styles, { name: 'AMSDesktopSearch' })

const TabPanel = ({
  index,
  value,
  children,
  ...otherProps
}) => {
  const classes = useStyles()
  const cn = clsx(classes.tabPanel, value !== index && classes.hidden)

  return (
    <div
      className={cn}
      role="tabpanel"
      id={`desktop-search-tabpanel-${index}`}
      aria-labelledby={`desktop-search-tab-${index}`}
      {...otherProps}
    >
      {children}
    </div>
  )
}

const ParametersScrollContainer = ({ ...searchParamProps }) => {
  const classes = useStyles()
  return (
    <div className={classes.parameters}>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
      >
        <SearchParameters {...searchParamProps} />
      </PerfectScrollbar>
    </div>
  )
}

const Results = observer(({ config }) => {
  const classes = useStyles()
  const store = useStore()

  if (!store.search.params.isSearchCategorySelected &&
    config.id !== 'favourites') return (
    <InfoOnly />
  )

  return (
    <>
      <div className={classes.results}>
        <PerfectScrollbar>
          <SearchResults searchId={config.options.search} />
        </PerfectScrollbar>
      </div>
      <div className={classes.map}>
        <SearchResultsMap searchId={config.options.search} />
      </div>
    </>
  )
})

const Info = () => {
  const classes = useStyles()
  return (
    <div className={classes.informationOnly}>
      <PerfectScrollbar>
        <Container maxWidth="md">
          <Box py={4}>
            <Information />
          </Box>
        </Container>
      </PerfectScrollbar>
    </div>
  )
}

const tabComponentsMap = {
  Information: Info,
  SearchResults: Results,
}

const TabComponent = ({ value, index, config }) => {
  const Component = tabComponentsMap[config.component]

  return (
    <TabPanel value={value} index={index}>
      <Component config={config} />
    </TabPanel>
  )
}

const DesktopSearch = observer(() => {
  const classes = useStyles()
  const store = useStore()
  const ui = store.search.ui
  const { tenantConfig: tc } = useConfig()
  const { track } = useAnalytics()

  const tabs = tc.search?.tabs
  const tab = ui.selectedTab

  // const [showParameters, setShowParameters] = useState(true)

  const [showSearch, setShowSearch] = useState(false)
  const [showLocation, setShowLocation] = useState(false)

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { isBot } = usePlatform()

  const initLocation = tc.search?.location?.initLocation
  const desktopParams = tc.search?.parameterGroups?.desktop
  const appBarParams = tc.search?.parameterGroups?.appBar

  // wait for the portal

  const [appBarPortal, setAppBarPortal] = useState(document.getElementById('search-params-app-bar'))

  useEffect(() => {
    if (appBarPortal) return

    window.setTimeout(() => {
      const p = document.getElementById('search-params-app-bar')
      if (p) setAppBarPortal(p)
    }, 10)
  }, [])

  // wait to show location dialog
  useEffect(() => {
    const show = !isBot &&
      initLocation === 'dialog' &&
      !store.search.ui.locationDialogShown &&
      store.search.params.location === null

    setTimeout(() => {
      if (show) {
        track('searchLocationDialogOpen', {
          category: 'Search',
          label: 'Show',
        })
      }
      setShowLocation(show)
    }, 1000)
  }, [
    initLocation,
    store.search.params.location,
    store.search.ui.locationDialogShown,
    track,
  ])

  const toggleShowSearchParams = (value) => {
    track('toggleShowSearchParams', {
      category: 'Search',
      label: value ? 'Show' : 'Hide',
    })

    setShowSearch(value)
  }

  const handleCloseLocationDialog = () => {
    track('searchLocationDialogClose', {
      category: 'Search',
      label: 'Close',
    })

    setShowLocation(false)
    store.search.ui.setLocationDialogShown(true)
  }

  return (
    <div className={classes.root}>

      <LocationDialog
        open={showLocation}
        onClose={handleCloseLocationDialog}
      />

      <DesktopSearchToolbar
        tabs={tabs}
        // selectedTab={tab}
        // onTabChange={setTab}
        showSearch={showSearch}
        onClickSearch={toggleShowSearchParams}
      />


      {appBarParams && appBarPortal && !isTablet &&
        <Portal node={appBarPortal}>
          <SearchParameters parameterGroup={appBarParams} />
        </Portal>
      }

      <div className={classes.content}>
        {!isTablet && !appBarParams &&
          <ParametersScrollContainer parameterGroup={desktopParams} />
        }

        {isTablet && (
          <SwipeableDrawer
            anchor="left"
            open={showSearch}
            onOpen={() => toggleShowSearchParams(true)}
            onClose={() => toggleShowSearchParams(false)}
          >
            <div className={classes.paramaterDrawer}>
              <SearchParameters parameterGroup={desktopParams} />
            </div>
          </SwipeableDrawer>
        )}

        {tabs.map((t, i) => (
          <TabComponent
            key={i}
            value={tab}
            index={i}
            config={t}
          />
        ))}
      </div>
    </div>
  )
})

const StyledDesktopSearch = DesktopSearch

export default StyledDesktopSearch

import React, { useState } from 'react'

import ReactMarkdown from 'markdown-to-jsx'
import { NavLink } from 'react-router-dom'
// import scrollIntoView from 'scroll-into-view-if-needed'

import { withStyles } from '@material-ui/core/styles'
import {
  Box,
  Typography,
  Divider,
  Link,
} from '@material-ui/core'

import MarkdownAccordion from 'ui/components/atoms/MarkdownAccordion'
import ImageAsset from 'ui/components/atoms/ImageAsset'
import HomeButtons from 'ui/components/atoms/HomeButtons'
import Logo from 'ui/components/atoms/Logo'
import PreviousSearch from 'ui/components/atoms/PreviousSearch'

const styles = (theme) => ({
  ul: {
    paddingInlineStart: '30px !important',
    [theme.breakpoints.down('sm')]: {
      paddingInlineStart: '20px !important',
    },

  },
  li: {
    marginTop: theme.spacing(1),
  },
  navLink: {
    fontSize: 16,
  },
});

const makeOptions = ({
  expanded,
  onAccordionClick = () => undefined,
}) => ({
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h5' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    h5: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    p: { component: Typography, props: { variant: 'body1', paragraph: true } },
    a: { component: Link },
    ul: {
      component: withStyles(styles)(({ classes, children }) => (
        <ul className={classes.ul}>
          {children}
        </ul>
      )),
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.li}>
          <Typography component="div" {...props} />
        </li>
      )),
    },
    hr: {
      component: Divider,
    },
    homebuttons: {
      component: HomeButtons,
    },
    disclaimer: {
      component: Box,
      props: { fontSize: 14, fontWeight: 300, color: '#666', pt: 6 },
    },
    accordion: {
      component: MarkdownAccordion,
      props: {
        expanded,
        onAccordionClick,
      },
    },
    navlink: {
      component: NavLink,
    },
    imgasset: {
      component: ImageAsset,
    },
    logo: {
      component: Logo,
    },
    previoussearch: {
      component: PreviousSearch,
    },
  },
})

const SimpleMarkdown = ({ ...props }) => {
  const [expanded, setExpanded] = useState(undefined)

  const handleAccordionClick = (panel) => {
    setExpanded(panel === expanded ? undefined : panel)
  }

  const options = makeOptions({
    expanded,
    onAccordionClick: handleAccordionClick,
  })

  return (
    <ReactMarkdown options={options} {...props} />
  )
}

export default SimpleMarkdown

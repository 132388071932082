import { types, getRoot } from 'mobx-state-tree'

import _ from 'lodash'

import { formatDistance } from '../utils/store-utils'
import { asArray } from '../utils/misc-helpers'
import { useConfig } from 'config/config'

const { tenantConfig } = useConfig()

const csMap = _.keyBy(tenantConfig.commissionedServiceLookup?.typeMap || [], 'category')

const badges = (tenantConfig.categories || [])
    .filter(c => !!c.badge)
    .map(c => ({
      // ...c.badge,
      tagId: c.badge.tagId,
      category: c.id,
      label: c.title,
      styles: {
        default: {
          backgroundColor: c.badge.backgroundColor,
        },
      },
    }))

export const Service = types
  .model({
    type: types.literal('service'),
    // id: types.refinement(types.identifier, identifier => identifier.indexOf('services_') === 0),
    id: '',
    search: '',
    category: '',
    operatingDays: '',
    name: '',
    address: '',
    phone: '',
    description: '',
    outletId: 0,
    commissionedServiceId: types.maybeNull(types.number, null),
    url: '',
    logoUrl: '',
    healthEngineId: types.maybeNull(types.number, null),
    healthEngineStatus: types.maybeNull(types.string, null),
    healthEngineAppointment: types.maybeNull(types.string, null),
    lat: 0.0,
    lng: 0.0,
    distance: 0.0,
    serviceChanges: types.maybeNull(types.model({
      openingHours: '',
      serviceChangesOrClosures: '',
      extraServicesOrEvents: '',
      emergencyContact: '',
      eligibilityConditions: '',
    })),
    serviceTags: types.array(types.number),
  })

  .views(self => ({
    get geometry() {
      return ({
        lat: self.lat,
        lng: self.lng,
      })
    },

    get distanceLabel() {
      return formatDistance(self.distance)
    },

    get isFavourite() {
      return getRoot(self).search.isFavourite(self.id)
    },

    get commissionedServiceTypes () {
      const root = getRoot(self)
      const cats = root.tenant.categoriesForCommissionedService(self.commissionedServiceId)

      const sc = root.search.activeCategoriesSet

      return cats.map(c => {
        const tm = csMap[c.id]
        return {
          label: c.title,
          icon: tm?.icon,
          category: c.id,
          tagId: tm?.tagId,
          active: sc.has(c.id),
          styles: {
            default: {
              backgroundColor: tm?.backgroundColor,
            },
          },
        }
      })
    },

    get badges() {
      const acs = getRoot(self).search.activeCategoriesSet

      return badges
        .filter(b => self.serviceTags.includes(b.tagId))
        .map(b => ({ ...b, active: acs.has(b.category) }))
    },

  }))

import React, { useCallback, useState, useEffect } from 'react'

// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { useAnalytics } from 'use-analytics'

import { cloneDeep, merge } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import {
  Box,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { useConfig } from 'config/config'
import { useStore } from 'data/store/store'
import { useCountRenders } from 'ui/hooks/useCountRenders'

import IconText from 'ui/components/atoms/IconText'
import Badge from 'ui/components/atoms/Badge'
import { CieLink } from 'ui/components/atoms/Link'

const { tenantConfig } = useConfig()

const useStyles = makeStyles(theme => ({
  resultCard: props => ({
    borderRadius: 0,
    boxShadow: props.selected ? '1px 2px 4px 0 rgba(0, 0, 0, 0.2)' : 'none',
    transform: props.selected ? 'translate(0px, -1px)' : 'none',
  }),
  separator: {
    margin: theme.spacing(2, 0),
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  topInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  headerIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mcdIcon: {
    maxHeight: 30,
  },
  datePill: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.7, 1),
    borderRadius: 3,
    fontSize: 18,
    fontWeight: 600,
  },
  datePillDiary: {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: '#417cbf',
    color: '#fff',
    padding: theme.spacing(0.7, 1),
    marginRight: theme.spacing(1),
    borderRadius: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
  },
  componentBlock: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  actions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& i': {
      fontSize: 18,
    },
  },
  distance: {
    marginLeft: 'auto',
    fontSize: 16,
    fontWeight: 300,
    color: grey[200],
  },
  logo: {
    maxHeight: 70,
    maxWidth: 120,
  },
  logoSmall: {
    maxHeight: 40,
    maxWidth: 90,
  },
  titleBlock: {
    margin: theme.spacing(0, 0, 2),
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
  },
  category: {
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  healthEngineContainer: {
    ...theme.typography.body1,
    // backgroundColor: '#f0f',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  healthEngineAppointment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'fex-start',
  },
  healthEngineButton: {
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  healthEngineButtonLabel: {
    flexDirection: 'column',
    fontSize: 12,
  },
}))

// Mapped Components

const Separator = () => {
  const classes = useStyles()
  return <div className={classes.separator} />
}

const Spacer = () => {
  const classes = useStyles()
  return <div className={classes.spacer} />
}

const HeaderIcon = ({ type, data }) => {
  const classes = useStyles()
  const { tenantConfig } = useConfig()

  switch (type) {
    case 'directory': return (
      <div className={classes.headerIcon}>
        <img
          className={classes.mcdIcon}
          src={tenantConfig.assets.mcdIcons.directory}
          alt="Directory Icon"
        />
      </div>
    )
    case 'diary': return (
      <div className={classes.headerIcon}>
        <div className={classes.datePillDiary}>
          {data.startDay} {data.startMonth}
        </div>
        <img
          className={classes.mcdIcon}
          src={tenantConfig.assets.mcdIcons.diary}
          alt="Diary Icon"
        />

      </div>
    )
    default: return (
      <div>{type}</div>
    )
  }
}

const Header = ({ config, data, variant }) => {
  const classes = useStyles()

  const type = config?.options?.type
  const showSeparator = config?.options?.showSeparator
  const logoClass = variant === 'map' ? classes.logoSmall : classes.logo

  return (
    <>
      <div className={classes.topInfo}>
        {/* {data.type === 'event' && (
          <div>
            <div className={classes.datePill}>{data.startDay} {data.startMonth}</div>
          </div>
        )} */}
        {type !== 'logo' && <HeaderIcon type={type} data={data} />}
        {type === 'logo' && (
          <div>
            {data.logoUrl && (
              <img
                className={logoClass}
                src={data.logoUrl}
                alt="logo"
              />
            )}
          </div>
        )}
        <div className={classes.distance}>
          {data.distanceLabel && (
            <IconText
              iconClass="fas fa-running"
              text={data.distanceLabel}
            />
          )}
        </div>
      </div>
      {showSeparator && <Separator />}
    </>
  )
}

const useBadgeListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 3),
  },
}))

const BadgeList = ({ config, data, variant }) => {
  const classes = useStyles()
  const badgeClasses = useBadgeListStyles()

  const { options } = config

  const badges = data.badges
    .map(cst => merge(cloneDeep(options), cst))

  if (!badges || badges.length === 0) return null

  return (
    <div className={clsx(classes.componentBlock, badgeClasses.root)}>
      {badges.map((badge, i) => <Badge key={i} {...badge} /> )}
    </div>
  )
}

const Title = ({ config, data, variant }) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  if (!(data.name || data.category)) return null

  const handleTitleClick = e => {
    e.stopPropagation()
    track(variant === 'map' ? 'searchResultMapTitleClick' : 'searchResultTitleClick', {
      category: 'SearchResults',
      label: data.name,
    })
  }

  return (
    <div className={classes.titleBlock}>
      <CieLink
        className={classes.title}
        type={data.type}
        path={data.url}
        // color="secondary"
        onClick={handleTitleClick}
      >
        {data.name}
      </CieLink>
      {config.showCategory && <div className={classes.category}>{data.category}</div>}
    </div>
  )
}

const Description = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.componentBlock}>
      <Typography
        variant="body1"
        element="div"
        dangerouslySetInnerHTML={{ __html: data.description }}
        />
    </div>
  )
}

const Details = ({ config, data }) => {
  const classes = useStyles()

  if (!config.fields) return null

  const items = config.fields
    .filter(f => data[f.key] && data[f.key] !== '--withheld--')
    .map(f => ({
      icon: f.icon,
      text: data[f.key],
    }))

  return (
    <div className={classes.componentBlock}>
      {items.map((v, i) => <IconText key={i} iconClass={v.icon} text={v.text} />)}
    </div>
  )
}

const ServiceChanges = ({ config, data }) => {
  const classes = useStyles()

  if (!data.serviceChanges) return null

  return (
    <div className={classes.componentBlock}>
      <Separator />
      <IconText iconClass="fas fa-bullhorn" text="Service Changes" />
      <Details config={config} data={data.serviceChanges} />
    </div>
  )
}

const HealthEngine = ({ config, data }) => {
  const classes = useStyles()
  const { appConfig } = useConfig()

  if (!data.healthEngineAppointment) return null

  const url = `${appConfig.directoryURL}/redirect?action=healthengine&outletId=${data.outletId}`

  return (
    <div className={classes.componentBlock}>
      <Separator />
      <div className={classes.healthEngineContainer}>

        <Box className={classes.healthEngineAppointment}>
          <Box mr={2}>
            <i className="fas fa-calendar"/>
          </Box>
          <Box flexDirection="column">
            <Typography variant="body1">Next Appointment</Typography>
            <Typography variant="body1">{data.healthEngineAppointment}</Typography>
          </Box>
        </Box>

        <Button
          classes={{
            root: classes.healthEngineButton,
            label: classes.healthEngineButtonLabel,
          }}
          variant="contained"
          color="primary"
          href={url}
          target="_blank"
          disableElevation
        >
          <div>BOOK NOW</div>
          <div>Powered by <strong>HealthEngine</strong></div>
        </Button>


      </div>
    </div>
  )
}

const componentMap = {
  Separator,
  Spacer,
  Header,
  BadgeList,
  Title,
  Description,
  Details,
  ServiceChanges,
  HealthEngine,
}

// Actions
// TODO: Add to config

const Actions = observer(({
  data,
  search,
}) => {
  const { track } = useAnalytics()
  const classes = useStyles()
  const [confirmDeleteFav, setConfirmDeleteFav] = useState(false)

  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const icon = data.id.match(/_fav$/)
    ? 'fas fa-trash'
    : data.isFavourite
    ? 'fas fa-heart'
    : 'far fa-heart'

  const handleFavouriteToggle = e => {
    e.stopPropagation()

    if (data.isFavourite) {
      setConfirmDeleteFav(true)
    } else {
      track('searchResultAddFavourite', {
        category: 'SearchResults',
        label: data.name,
      })

      search.control.toggleFavourite(data)
    }
  }

  const handleClose = e => {
    e.stopPropagation()
    setConfirmDeleteFav(false)
  }

  const handleConfirm = e => {
    track('searchResultDeleteFavourite', {
      category: 'SearchResults',
      label: data.name,
    })

    e.stopPropagation()
    search.control.toggleFavourite(data)
    setConfirmDeleteFav(false)
  }

  return (
    <div className={clsx(classes.componentBlock, classes.actions)}>

      <IconButton
        onClick={handleFavouriteToggle}
      >
        <i className={clsx(icon, 'fa-fw')} />
      </IconButton>

      <Dialog
        open={confirmDeleteFav}
        onClose={handleClose}
        // fullScreen={isMobile}
      >
        <DialogTitle>
          Remove Favourite
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove <strong>{data.name}</strong> from your favourites list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={handleConfirm}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

// Result

const SearchResult = React.memo(observer(({
  // config,
  search,
  data,
  variant = 'list',
  selected = false,
  onClickResult = () => undefined,
 }) => {

  // for optimising memoized renders

  if (false) {
    useCountRenders(data.id)

    useEffect(() => {
      console.log(`effect[${data.id}]`)
    }, [data, selected, onClickResult, search])
  }

  const classes = useStyles({ selected })
  const config = tenantConfig.search.results[data.type]

  const handleResultClick = useCallback(e => {
    e.stopPropagation()
    onClickResult(data)
  }, [data, onClickResult])

  const ResultContents = () => (
    <>
      {config.components.map((c, i) => {
        if (!(c.component in componentMap)) return null
        if (c.hideVariant === variant) return null
        const Component = componentMap[c.component]

        return (
          <Component
            key={i}
            config={c}
            search={search}
            data={data}
            variant={variant}
          />
        )
      })}
    </>
  )

  if (variant === 'map') {
    return <ResultContents />
  }

  return (
    <Box px={2} py={1} id={data.id}>
      <Card
        variant="outlined"
        elevation={selected ? 3 : 1}
        className={classes.resultCard}
        onClick={handleResultClick}
      >
        <CardContent>
          {false && <Typography variant="body1">{data.id}</Typography>}

          <ResultContents />

          <Actions {...{ search, data }}/>

        </CardContent>
      </Card>

    </Box>
  )
}))

export default SearchResult
